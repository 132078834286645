import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import LetsWork from "../../components/lets-work/LetsWork";
import LogoBox from "../../components/logo-box/LogoBox";
import List from "../../components/list/List";
import TitleBox from "../../components/title-box/TitleBox";

import {ReactComponent as EyeTracking} from "../../images/research/eye-tracking.svg";
import {ReactComponent as CsAndGsr} from "../../images/research/cs-and-gsr.svg";
import {ReactComponent as EegAndQeeg} from "../../images/research/eeg-and-qeeg.svg";
import {ReactComponent as Observation} from "../../images/research/observation.svg";


import Cooperation1 from '../../images/index/digital.svg';
import Cooperation2 from '../../images/index/elearning.svg';
import Cooperation3 from '../../images/index/ecommerce.svg';
import Cooperation4 from '../../images/index/btl-atl.svg';

import BannerBg from '../../images/research/banner-bg.svg';
import ResearchTechniquesBg1 from '../../images/research/research-techniques-bg-1.svg';
import ResearchTechniquesBg2 from '../../images/research/research-techniques-bg-2.svg';
import {initGA, PageView} from "../../components/tracking/Tracking";

class Research extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    handleClick() {
        window.scrollTo(0, 0);
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Research">
                <div className="ResearchTechniques">
                    <section className="py-xl" style={{backgroundImage: 'url(' + BannerBg + ')', backgroundSize: '100% auto', backgroundPosition: 'center top 10%', backgroundRepeat: 'no-repeat'}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-xl-8 text-center">
                                    <JumbotronTitleBox title="Research techniques" paragraph="We combine biometric research with neuromarketing and psychophysiological tests to get the best results in preparing marketing communication, sales content and e-learning methods.<br /><br />We use the proprietary system and research process developed jointly with scientific authorities operating in the field of neuromarketing at leading universities in Poland and abroad. The form of research we have proposed has not been used anywhere in the world." />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="techniques">
                        <div className="container" style={{backgroundImage: 'url(' + ResearchTechniquesBg1 + '), url(' + ResearchTechniquesBg2 + ')', backgroundRepeat: 'no-repeat, no-repeat', backgroundSize: '45% auto, 45% auto', backgroundPosition: 'right top, left bottom 10%'}}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="masonry text-center">
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <EyeTracking />
                                                </div>
                                                <h4>Eye tracking</h4>
                                                <p className="large">The analysis of eye movements, which allows you to precisely determine which places attract users' attention most.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <CsAndGsr />
                                                </div>
                                                <h4>SC and GSR</h4>
                                                <p className="large">The study of skin activity and psychophysiological tests, allowing the discovery of real opinions and emotional reactions.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <EegAndQeeg />
                                                </div>
                                                <h4>EEG and QEEG</h4>
                                                <p className="large">The study of brain-wave length (electroencephalography) and brain mapping to determine the effectiveness of the used means.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <Observation />
                                                </div>
                                                <h4>Observation and surveys</h4>
                                                <p className="large">Surveys and in-depth interviews that complement all techniques.</p>
                                            </div>
                                        </LogoBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                {/*<section className="py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8 text-center">*/}
                                {/*<JumbotronTitleBox title="Work" paragraph="Discover stories of successes that we achieve together with our business partners. Maybe yours will be next?" />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                {/*<section className="cards py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="digital" paragraph="Zaprojektuj strony internetowe oraz nośniki digitalowe, które będą angażować i zwracać uwagę internautów." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="e-learning" paragraph="Stwórz dla swoich pracowników łatwiej przyswajalne, ciekawsze i skuteczniejsze e-kursy." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="Zostań respondentem" paragraph="Spraw, aby Twoje karty produktowe  dobrze konwertowały w każdym środowisku sklepowym." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="Zamów badania" paragraph="Zbadaj wielopoziomowo sentyment względem tworzonych przez Ciebie materiałów." button="Więcej"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <div className="Index">
                    <section className="py-xl position-relative" style={{backgroundColor: '#FAFAFA'}}>
                        <div className="dot-bg"></div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-lg-8 col-xl-7 text-center">
                                    <TitleBox title="The scope and areas of cooperation" paragraph="How can you use knowledge about motivation?" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pb-xl" style={{backgroundColor: '#FAFAFA'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/en/research-offer/digital-research" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation1} alt="digital" />
                                                <h4>digital</h4>
                                            </div>
                                            <p className="mb-xs"><b>Design websites and digital media that will engage and attract the attention of Internet users.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badanie UX stron www</li>*/}
                                            {/*<li>testy AB</li>*/}
                                            {/*<li>badanie zaangażowania</li>*/}
                                            {/*<li>badanie atrakcyjności layoutu</li>*/}
                                            {/*</ul>*/}
                                            <List list={['UX survey of websites', 'AB tests', 'engagement study', 'study of layout attractiveness']} />
                                            <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/en/research-offer/elearning-research" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation2} alt="e-learning" />
                                                <h4>e‑learning</h4>
                                            </div>
                                            <p className="mb-xs"><b>Create readily available, interesting and effective e-courses for your employees.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badanie UX materiałów e‑learningowych</li>*/}
                                            {/*<li>analiza efektywności materiałów szkoleniowych</li>*/}
                                            {/*</ul>*/}
                                            <List list={['UX study of e-learning materials', 'analysis of the effectiveness of training materials']} />
                                            <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/en/research-offer/ecommerce-research" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation3} alt="e-commerce" />
                                                <h4>e‑commerce</h4>
                                            </div>
                                            <p className="mb-xs"><b>Make your rich product cards convert well in any store environment.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>optymalizacja konwersji</li>*/}
                                            {/*<li>badanie motywacji zakupowych</li>*/}
                                            {/*<li>analiza ścieżki użytkownika</li>*/}
                                            {/*<li>badanie efektywności kart produktowych</li>*/}
                                            {/*</ul>*/}
                                            <List list={['conversion optimization', 'research on purchase motivation', 'user path analysis', 'research on the effectiveness of rich product cards']} />
                                            <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/en/research-offer/btl-atl-research/" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation4} alt="BTL/ATL" />
                                                <h4>BTL/ATL</h4>
                                            </div>
                                            <p className="mb-xs"><b>Investigate the sentiment with respect to the materials you create.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badania sentymentu dla marek i&nbsp;produktów</li>*/}
                                            {/*<li>badania sentymentu dla materiałów wideo</li>*/}
                                            {/*<li>badania zaangażowania</li>*/}
                                            {/*</ul>*/}
                                            <List list={['sentiment research for brands and products', 'sentiment research for video materials', 'engagement research']} />
                                            <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row justify-content-center mt-sm">*/}
                                {/*<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">*/}
                                    {/*<button className="btn btn-outline-dark w-100">Idź do strony <i className="material-icons">arrow_forward</i></button>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                        <div className="container mt-l">
                            <div className="row">
                                <div className="col-12">
                                    <LetsWork language="en" />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        );
    }
}

export default Research;