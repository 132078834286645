import React, { Component } from 'react';
import './JumbotronTitleBox.scss';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

class JumbotronTitleBox extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        AOS.init({
            duration : 500
        })
    }

    handleClick() {
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        let logo = <div className="logo" data-aos="fade-up">{ this.props.logo }</div>;
        let title = <h2 className="bigger" data-aos="fade-up">{ this.props.title }</h2>;
        let paragraph = <p className="introduction" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html:this.props.paragraph}}></p>;
        let link = <div className="mt-sm" data-aos="fade-up" data-aos-delay="400"><a href={this.props.link} onClick={this.handleClick}>{this.props.link} <i className="material-icons">arrow_forward</i></a></div>
        let button =<div className="mt-sm" data-aos="fade-up" data-aos-delay="400"><Link to={this.props.route} onClick={this.handleClick}><button className="btn btn-outline-dark">{this.props.button} <i className="material-icons">arrow_forward</i></button></Link></div>
        return (
            <div className="JumbotronTitleBox">
                { this.props.logo ? logo : '' }
                { this.props.title ? title : '' }
                { this.props.paragraph ? paragraph : '' }
                { this.props.children }
                { this.props.link ? link : '' }
                { this.props.button ? button : '' }
            </div>
        );
    }
}

export default JumbotronTitleBox;
