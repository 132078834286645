import React, { Component } from 'react';
import { BrowserRouter as Router, Link, NavLink } from 'react-router-dom';
import {Event} from "../tracking/Tracking";
import './Navigation.scss'; // Tell Webpack that Button.js uses these styles
import Menu from '../menu/Menu.js';
import {ReactComponent as Logo} from '../../images/Logo.svg';
import Modal from "../modal/Modal";
import ContactForm from "../contact-form/ContactForm";
import Accordion from "../accordion/Accordion";


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isToggleOn: false,
            invertedNav: false,
            prevScrollpos: window.pageYOffset,
            visible: true,
            orderResearchVisible: false,
            becomeArespondentVisible: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.scrollTop = this.scrollTop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleResize = this.handleResize.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            isToggleOn: !state.isToggleOn
        }));
        window.scrollTo(0, 0);
        document.body.classList.toggle('is-nav-open');
    }

    closeMenu() {
        this.setState(state =>({
            isToggleOn: false
        }));
        document.body.classList.remove('is-nav-open');
    }

    scrollTop() {
        window.scrollTo(0, 0);
        this.setState(state => ({
            invertedNav: false
        }))
    }

    handleScroll(ev) {
        // const videoSection = document.getElementById('video-section-1');
        // if (videoSection) {
        //     const sectionStart = videoSection.offsetTop;
        //     const sectionEnd = sectionStart + videoSection.offsetHeight;
        //     let scrollPos = window.pageYOffset;
        //     if (scrollPos < sectionEnd && scrollPos > sectionStart) {
        //         this.setState({
        //             invertedNav: true
        //         })
        //     } else {
        //         this.setState({
        //             invertedNav: false
        //         })
        //     }
        // }

        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    }

    handleResize() {
        this.handleScroll();
    }

    openModal(modalState) {
        this.setState(() => {
            return {[modalState]: true}
        });
        document.body.classList.add('disable-scroll');
    }

    closeModal(modalState) {
        this.setState(() => {
            return {[modalState]: false}
        });
        document.body.classList.remove('disable-scroll');
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
        window.removeEventListener('resize', this.handleResize);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className={"Navigation " + (this.state.isToggleOn ? "open " : " ") + " " + (this.state.invertedNav ? "inverse " : " ") + (!this.state.visible ? "navbar-hidden" : " ")}>
                <nav className="nav-outer py-xs fixed-top navbar navbar-expand-lg" >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 d-flex justify-content-between">
                                <div className="left-part">
                                    {this.props.context.state.language === 'en' ? (
                                        <Link to="/en/" onClick={(ev) => {this.closeMenu(); this.scrollTop()}}><div className="logo">
                                            <Logo />
                                        </div></Link>
                                    ) : (
                                        <Link to="/" onClick={(ev) => {this.closeMenu(); this.scrollTop()}}><div className="logo">
                                            <Logo />
                                        </div></Link>
                                    )}
                                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                        {this.props.context.state.language === 'en' ? (
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <span className="nav-link"><NavLink to="/en/lab/" activeClassName="active" onClick={this.scrollTop}>Lab</NavLink></span>
                                                </li>
                                                <li className="nav-item">
                                                    <span className="nav-link"><NavLink to="/en/research-offer/" activeClassName="active" onClick={this.scrollTop}>Research</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    <span className="nav-link"><NavLink to="/en/research-process/" activeClassName="active" onClick={this.scrollTop}>Research process</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    {/*<a href="http://lab.af.agency/blog" class="nav-link" onClick={this.scrollTop}>Blog</a>*/}
                                                    <span className="nav-link"><NavLink to="/en/blog" activeClassName="active" onClick={this.scrollTop}>Blog</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    <span className="nav-link"><NavLink to="/en/contact" activeClassName="active" onClick={this.scrollTop}>Contact</NavLink></span>
                                                </li>
                                            </ul>
                                            ) : (
                                            <ul className="nav">
                                                <li className="nav-item">
                                                    <span className="nav-link"><NavLink to="/laboratorium/" activeClassName="active" onClick={this.scrollTop}>Laboratorium</NavLink></span>
                                                </li>
                                                <li className="nav-item">
                                                    <span className="nav-link"><NavLink to="/oferta-badan/" activeClassName="active" onClick={this.scrollTop}>Badania UX/UI</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    <span className="nav-link"><NavLink to="/proces-badawczy/" activeClassName="active" onClick={this.scrollTop}>Proces badawczy</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    {/*<a href="http://lab.af.agency/blog" class="nav-link" onClick={this.scrollTop}>Blog</a>*/}
                                                    <span className="nav-link"><NavLink to="/blog" activeClassName="active" onClick={this.scrollTop}>Blog</NavLink></span>
                                                </li>
                                                <li className={"nav-item "}>
                                                    <span className="nav-link"><NavLink to="/kontakt" activeClassName="active" onClick={this.scrollTop}>Kontakt</NavLink></span>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="right-part">

                                    <div className="contact-us d-none d-lg-inline-block">
                                        <button className="btn btn-coral mx-2" onClick={() => {this.openModal('orderResearchVisible'); Event("ORDER_RESEARCH", "Showed order research modal", "NAVIGATION")}}>{this.props.context.state.language === 'en' ? 'Order tests' : 'Zamów badania'}</button>
                                        <a onClick={() => {this.openModal('becomeArespondentVisible'); Event("BECOME_A_RESPONDENT", "Showed become a respondent modal", "NAVIGATION")}} className="coral mx-2"><i className="material-icons">person_add</i>{this.props.context.state.language === 'en' ? 'Become a respondent' : 'Zostań respondentem'}</a>
                                    </div>
                                    <div className="language d-none d-lg-inline-block">
                                        {this.props.context.state.language === 'en' ? (<Link to="/" onClick={this.props.context.changeLanguage} data-language=""><i className="material-icons">language</i>Polski</Link>) : (<Link to="/en/" onClick={this.props.context.changeLanguage} data-language="en"><i className="material-icons">language</i>English</Link>)}
                                    </div>
                                    <div className="hamburger navbar-toggler"  data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={this.handleClick}>
                                        <div className="hamburger-inner">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
                <Menu open={this.state.isToggleOn} fToggle={this.handleClick} language={this.props.context.state.language} changeLanguage={this.props.context.changeLanguage} />
                <Modal id="modal-order-research" visible={this.state.orderResearchVisible} fClose={() => this.closeModal('orderResearchVisible')}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-6 py-l">
                                <ContactForm formType="1" formName="or2_" formLanguage={this.props.context.state.language}/>
                            </div>
                            <div className="col-12 col-lg-5 align-self-center">
                                {this.props.context.state.language === 'en' ? (
                                    <Accordion>
                                        <div label="Increase the effectiveness of your activities">
                                            <p>
                                                We will help you increase conversions and implement KPIs.
                                                The offer is addressed to people active in the following areas
                                                of business: e-commerce, BTL / ATL, digital and e-learning.
                                            </p>
                                        </div>
                                        <div label="Learn the expectations of consumers">
                                            <p>
                                                Thanks to the unique quality knowledge, you will not only offer your
                                                clients and employees accurate and innovative solutions, but also
                                                gain knowledge on how to implement them to the organization.
                                            </p>
                                        </div>
                                        <div label="Use cooperation and innovation in the PR strategy">
                                            <p>
                                                You can use the cooperation with us as part of an impressive PR
                                                strategy. Be innovative in your industry thanks to solutions that
                                                will help you achieve a truly expert image.
                                            </p>
                                        </div>
                                    </Accordion>
                                ) : (
                                    <Accordion>
                                        <div label="Zwiększ efektywność swoich działań">
                                            <p>
                                                Pomożemy Ci w&nbsp;zwiększeniu konwersji i&nbsp;realizacji KPI.
                                                Oferta jest skierowana do&nbsp;osób aktywnych w&nbsp;następujących
                                                obszarach biznesu: e‑commerce, BTL/ATL, digital i&nbsp;e‑learning.
                                            </p>
                                        </div>
                                        <div label="Poznaj oczekiwania konsumentów">
                                            <p>
                                                Dzięki unikatowej wiedzy jakościowej nie tylko zaproponujesz swoim
                                                klientom oraz pracownikom celne i&nbsp;nowatorskie rozwiązania,
                                                ale&nbsp;również zdobędziesz wiedzę, jak je wdrożyć,
                                                zastosować w&nbsp;organizacji.
                                            </p>
                                        </div>
                                        <div label="Wykorzystaj współpracę i innowacyjność w strategii PR-owej">
                                            <p>
                                                Współpracę z&nbsp;nami możesz wykorzystać jako element robiącej wrażenie
                                                strategii PR-owej. Bądź innowacyjny w&nbsp;swojej branży dzięki nowatorskim
                                                rozwiązaniom, które pomogą Ci w&nbsp;osiągnięciu prawdziwie eksperckiego wizerunku.
                                            </p>
                                        </div>
                                    </Accordion>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal id="modal-become-a-respondent" visible={this.state.becomeArespondentVisible} fClose={() => this.closeModal('becomeArespondentVisible')}>
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-12 col-lg-6 py-l">
                                <ContactForm formName="br2_" formLanguage={this.props.context.state.language}/>
                            </div>
                            <div className="col-12 col-lg-5 align-self-center">
                                {this.props.context.state.language === 'en' ? (
                                    <Accordion className="coral">
                                        <div label="Real impact on the services market">
                                            <p>
                                                The research results with your participation will shape the future
                                                of the services and products market in Poland and in the world.
                                                We work with large brands that are sure to appear in your everyday life.
                                                Now you can make their offer even more suited to what you really expect.
                                            </p>
                                        </div>
                                        <div label="Unique experience">
                                            <p>
                                                Take part in our research, and you will also get to know yourself
                                                better: it turns out that often what we take for granted is not true.
                                                You will discover how your brain really reacts and what emotions
                                                accompany the browsing of the content you are surrounded by on a daily basis.
                                            </p>
                                        </div>
                                        <div label="The opportunity to win prizes">
                                            <p>
                                                As a thank you for your time, we will offer attractive rewards to the
                                                most active respondents. Their value will vary depending on the duration of the given test.
                                            </p>
                                        </div>
                                    </Accordion>
                                ) : (
                                    <Accordion className="coral">
                                        <div label="Realny wpływ na rynek usług">
                                            <p>
                                                Wyniki badań z&nbsp;Twoim udziałem będą kształtowały przyszłość rynku usług
                                                oraz produktów w&nbsp;Polsce i&nbsp;na&nbsp;świecie. Współpracujemy
                                                z&nbsp;dużymi markami, które z&nbsp;pewnością pojawiają się w&nbsp;Twojej
                                                codzienności. Teraz możesz sprawić, aby ich oferta jeszcze bardziej odpowiadała temu, czego naprawdę oczekujesz.
                                            </p>
                                        </div>
                                        <div label="Unikatowe doświadczenie">
                                            <p>
                                                Weź udział w&nbsp;naszych badaniach, a&nbsp;poznasz również lepiej siebie:
                                                okazuje się, że&nbsp;często to, co&nbsp;bierzemy za&nbsp;pewnik, mija się
                                                z&nbsp;prawdą. Odkryjesz, jak tak naprawdę reaguje Twój mózg i&nbsp;jakie
                                                emocje towarzyszą przeglądaniu przez Ciebie treści, którymi jesteś otoczony na co&nbsp;dzień.
                                            </p>
                                        </div>
                                        <div label="Możliwość zdobycia nagród">
                                            <p>
                                                W&nbsp;podziękowaniu za&nbsp;poświęcony nam czas zaoferujemy najbardziej
                                                aktywnym respondentom atrakcyjne nagrody. Ich wartość będzie się różniła
                                                w&nbsp;zależności od&nbsp;czasochłonności danego badania.
                                            </p>
                                        </div>
                                    </Accordion>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default Navigation;
