import React, { Component } from 'react';
import TitleBox from "../components/title-box/TitleBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import Jumbotron from "../components/jumbotron/Jumbotron";
import LetsWork from "../components/lets-work/LetsWork";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../components/counter-item/CounterItem";

import BannerImg from "../images/research-process/research-design/banner-img.svg";

import Image from "../images/Image.jpg";

import ResearchProcessSecondaryNav from "../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../components/tracking/Tracking";

class ResearchDesign extends Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this)
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="ResearchDesign">
                <ResearchProcessSecondaryNav active="1" />
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Projektowanie badań" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">ETAP 1</h6>
                                <JumbotronTitleBox title="Projektowanie badań" paragraph="Zaproponujemy Ci trafne metodologie i&nbsp;spersonalizowane scenariusze badawcze."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan" className="active"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc mt-xl coral">ETAP 1</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">

                                    <h4>Tworzymy scenariusz badawczy</h4>
                                    <p className="mb-sm introduction">
                                        Na etapie projektowania badań uwzględnimy indywidualne potrzeby Twojego biznesu
                                        – tak, aby&nbsp;wiedza, którą uzykasz w&nbsp;wyniku analizy zaowocowała
                                        wzrostem KPI. Lata doświadczeń i&nbsp;partnerstwa z&nbsp;markami z&nbsp;różnych
                                        branż pozwalają nam na&nbsp;szybkie i&nbsp;efektywne działanie.
                                    </p>
                                    <h4>Patrzymy w przyszłość</h4>
                                    <p className="mb-sm introduction">
                                        Standardowa analityka to&nbsp;obecnie za&nbsp;mało, dlatego postanowiliśmy
                                        połączyć ją z&nbsp;badaniami motywacji konsumenckiej. Dzięki temu możemy
                                        trafnie przewidywać przyszłość danej branży oraz skutecznie pomagać
                                        w&nbsp;osiąganiu założonych, długoterminowych celów.
                                    </p>
                                    <h4>Organizujemy otoczenie badawcze</h4>
                                    <p className="mb-sm introduction">
                                        Starannie dobierzemy grupę respondentów, aby&nbsp;jak najdokładniej
                                        odzwierciedlała Twoich obecnych konsumentów. Posiadamy również możliwość
                                        stworzenia otoczenia badawczego, które będzie analizowało motywację grupy
                                        docelowej, którą dopiero chcesz pozyskać.
                                    </p>
                                    <h4>Szukamy prawdy, badając emocje</h4>
                                    <p className="mb-sm introduction">
                                        Wiemy, że w&nbsp;wartościowych badaniach najważniejszy jest czynnik ludzki.
                                        Uwzględnimy narzędzia, metodologie i&nbsp;wykorzystanie nowoczesnego sprzętu,
                                        który pozwala na zbadanie emocji i&nbsp;prawdy ukrytej za&nbsp;opiniami.
                                    </p>
                                    <h4>Budujemy wizerunek lidera innowacji</h4>
                                    <p className="mb-sm introduction">
                                        W&nbsp;etapie projektowania badań możemy uwzględnić możliwość podjęcia stałej
                                        współpracy w&nbsp;zakresie działania laboratorium badawczego. Nie tylko na&nbsp;bieżąco
                                        będziesz otrzymywać celne rekomendacje, ale&nbsp;również zyskasz wizerunek
                                        lidera-innowatora branży.
                                    </p>
                                    <h4>Wartościowa wiedza w krótkim czasie</h4>
                                    <p className="mb-sm introduction">
                                        Wspólnie z&nbsp;Tobą ustalimy satysfakcjonujący Cię harmonogram prac.
                                        Szybszy i&nbsp;pełniejszy dostęp do&nbsp;jakościowej wiedzy pozwoli Ci
                                        na&nbsp;wyprzedzenie działań Twoich największych konkurentów rynkowych.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="pt-sm pb-l next-section">
                    <div className="container">
                        <Link to="/proces-badawczy/technika-laczenia-metod-badawczych" className="d-block" onClick={this.scrollTop}>
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-2">

                                    <h6 className="uc coral">Przejdź do etapu nr 2</h6>
                                    <TitleBox title="Łączenie metod" paragraph="Dobór sprzętu, oprogramowania i narzędzi." />

                            </div>
                            <div className="col-12 col-lg-2 align-self-end">
                                <div className="rounded-arrow"></div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}

export default ResearchDesign;