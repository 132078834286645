import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import JobBox from "../components/job-box/JobBox";
import TitleBox from "../components/title-box/TitleBox";
import LogoBox from "../components/logo-box/LogoBox";
import LetsWork from "../components/lets-work/LetsWork";

class Careers extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Careers">
                <section className="my-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8 text-center">
                                <JumbotronTitleBox title="Join us" paragraph="We are a team that is looking for people like us — open-minded, cooperation friendly and always ready to achieve. Interested? Check if we have something for you!" />
                                <a href="#">Open positions <i className="material-icons">arrow_forward</i></a>
                                <a href="#">Benefits <i className="material-icons">arrow_forward</i></a>
                                <a href="#">Recruitment process <i className="material-icons">arrow_forward</i></a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="open-positions pb-xl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="Art Director" hours="Full Time" route="/careers/1"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="Graphic Designer" hours="Practice" route="/careers/1"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="New Biz Manager" hours="Full Time" route="/careers/1"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="Account Manager" hours="Full Time" route="/careers/1"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="Full-stack developer" hours="Full Time" route="/careers/1"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                                <JobBox title="Copywriter" hours="Full Time" route="/careers/1"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="benefits my-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8">
                                <TitleBox title="Benefits" paragraph="Here are benefits, which let you develop your skills and make working with us nice and comfortable every day." />
                            </div>
                        </div>
                    </div>
                    <div className="container mt-m">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Amibtious projects for leading brands</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Employment contract or B2B</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Health Insurance</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Multisport Card</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Possibility of remote work</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Relax zone with big pillows</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Integration trips and meetings</h5>
                                    </div>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                                <LogoBox>
                                    <div className="py-xs text-center">
                                        <div className="placeholder"></div>
                                        <h5>Office in a historic tenement house</h5>
                                    </div>
                                </LogoBox>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="recruitment-process py-xl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Our recruitment process</h3>
                                    <ol>
                                        <li>Analysis of the applications</li>
                                        <li>Contact with selected candidates</li>
                                        <li>Meeting at the office</li>
                                        <li>Recruitment task or second meeting with a potential supervisor</li>
                                        <li>Feedback on the recruitment process</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <LetsWork />
            </div>
        );
    }
}

export default Careers;