import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import LetsWork from "../components/lets-work/LetsWork";

class Culture extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Culture">
                <section className="my-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8 text-center">
                                <JumbotronTitleBox title="Design is us" paragraph="Everything that we have created also creates us. We maintain the balance between professionalism and simple joy of life, to give the best of it every day." />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="horizontal-masonry">

                </section>
                <section className="advantages my-xxl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Transparency</h3>
                                    <p className="introduction">The right to express your opinion and the strength of transparent communication.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Excellence</h3>
                                    <p className="introduction">We are professionals – we know our strengths and on them we build the position of experts and leaders in our field.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Engagement</h3>
                                    <p className="introduction">With similar commitment, we develop our clients as well as our talents – people are always the greatest value for us.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Optimism</h3>
                                    <p className="introduction">We combine experience with youthful energy and professional efficiency with everyday experiences that we gain outside of work.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <LetsWork />
            </div>
        );
    }
}

export default Culture;