import React, { Component } from 'react';
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../../components/contact-form/ContactForm";

import { Link, animateScroll as scroll } from 'react-scroll';


import BannerImg from "../../images/research/digital/banner-img.svg"
import ResearchSecondaryNav from "../../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../../components/tracking/Tracking";

class DigitalResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="DigitalResearch">
                <ResearchSecondaryNav active="1" language="en"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="digital" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">digital</h5>
                                <JumbotronTitleBox title="Improving online communication" paragraph="Analyzed and prepared on the basis of the research report, websites and other digital media will fulfill their task better, attracting the attention of the recipient and increasing his involvement."/>
                                <Link to="ContactForm" smooth={true}><button className="btn btn-primary btn-violet mt-s">Order tests</button></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Choose tests</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        User path analysis
                                    </h4>
                                    <p className="large">
                                        This action aims to optimize the presentation of information on the website
                                        so that navigating the website is more intuitive and simpler. The standard
                                        scope is based on big data analysis, and the extended scope includes
                                        eyetracking and working with the focus group.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        A/B tests
                                    </h4>
                                    <p className="large">
                                        Using modern scientific tools, we are able to carry out A / B testing
                                        of advertising formats in laboratory conditions. The addition of EEG to this
                                        research creates an advanced set of tests that allows you to look at whether
                                        the method of communication is interesting for the recipient.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Research on the involvement and attractiveness of layouts
                                    </h4>
                                    <p className="large">
                                        The analysis of the website layout based on eyetracking and psychophysiological
                                        tests allows to examine the level of emotion and interest that accompanies
                                        the website's reception.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">04</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Development of an individual research process
                                    </h4>
                                    <p className="large">
                                        Do you need an individually tailored research process to measure your activities
                                        in the digital area? Our team will design studies precisely tailored to your
                                        needs. Fill out the application form and we will contact you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{backgroundImage: 'url(' + ContactFormBg + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'left 10% bottom 30%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Leave your details and we will present you with a research plan</h2>
                                <p className="introduction">
                                    Write to us and tell us about your needs, and we will come back with a proposal for research cooperation that will result in the flourishing of your business.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2" formLanguage="en" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default DigitalResearch;