import React, { Component } from 'react';
import ContactForm from "../components/contact-form/ContactForm";
import Accordion from "../components/accordion/Accordion";
import Contact from "./Contact";
import {initGA, PageView} from "../components/tracking/Tracking";

class OrderResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="OrderResearch">
                {/*<section className="py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8">*/}
                                {/*<JumbotronTitleBox title="Zamów badania" paragraph="Uzyskaj unikatową wiedzę jakościową, skorelowaną z analityką. Napisz do nas, a zaproponujemy rozwiązanie dopasowane do Twoich potrzeb."/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article>
                    <section>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6">
                                    <ContactForm formType="1" formName="or_" />
                                </div>
                                <div className="col-12 col-lg-5 align-self-center">
                                    {/*<h4>Zwiększysz konwersję i osiągniesz mistrzowskie KPI</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Pomożemy Ci w imponującym zwiększeniu efektywności Twoich działań, niezależnie od branży, w jakiej koncentrujesz swoje działania. Nasza oferta jest skierowana do biznesów działających w e-commerce, BTL/ATL, digital i e-learningu.*/}
                                    {/*</p>*/}
                                    {/*<h4>Zyskasz przewagę nad swoją konkurencją</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Znajdziemy dla Ciebie ścieżkę rozwoju i rozwiązania, które pomogą Ci w wyprzedzeniu konkurencji. Tym samym będziesz mógł zaproponować swoim klientom celne i nowatorskie rozwiązania, których nie znajdą nigdzie indziej.*/}
                                    {/*</p>*/}
                                    {/*<h4>Dowiesz się, czego oczekują Twoi konsumenci</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Twoje przedsiębiorstwo zyska wizerunek user friendly, czyli będzie postrzegane jako takie, które bierze pod uwagę potrzeby i oczekiwania użytkowników. Wierzymy, że świadomy biznes to nie tylko tabele i wyniki, ale również wychodzenie naprzeciw pragnieniom.*/}
                                    {/*</p>*/}
                                    {/*<h4>Zdobędziesz imponujący PR w swojej branży</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Współpracę z nami możesz wykorzystać jako element robiącej wrażenie strategii PR-owej. Bądź innowacyjny w swojej branży dzięki nowatorskim rozwiązaniom, które pomogą Ci w osiągnięciu prawdziwie eksperckiego wizerunku.*/}
                                    {/*</p>*/}
                                    <Accordion>
                                            <div label="Zwiększ efektywność swoich działań">
                                                <p>
                                                    Pomożemy Ci w&nbsp;zwiększeniu konwersji i&nbsp;realizacji KPI.
                                                    Oferta jest skierowana do&nbsp;osób aktywnych w&nbsp;następujących
                                                    obszarach biznesu: e‑commerce, BTL/ATL, digital i&nbsp;e‑learning.
                                                </p>
                                            </div>
                                        <div label="Poznaj oczekiwania konsumentów">
                                            <p>
                                                Dzięki unikatowej wiedzy jakościowej nie tylko zaproponujesz swoim
                                                klientom oraz pracownikom celne i&nbsp;nowatorskie rozwiązania,
                                                ale&nbsp;również zdobędziesz wiedzę, jak je wdrożyć,
                                                zastosować w&nbsp;organizacji.
                                            </p>
                                        </div>
                                        <div label="Wykorzystaj współpracę i innowacyjność w strategii PR-owej">
                                            <p>
                                                Współpracę z&nbsp;nami możesz wykorzystać jako element robiącej wrażenie
                                                strategii PR-owej. Bądź innowacyjny w&nbsp;swojej branży dzięki nowatorskim
                                                rozwiązaniom, które pomogą Ci w&nbsp;osiągnięciu prawdziwie eksperckiego wizerunku.
                                            </p>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                {/*<section className="ContactForm py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8">*/}
                                {/*<ContactForm />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <Contact />
            </div>
        );
    }
}

export default OrderResearch;