import React, { Component } from 'react';
import TitleBox from "../components/title-box/TitleBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import Jumbotron from "../components/jumbotron/Jumbotron";
import LetsWork from "../components/lets-work/LetsWork";

class Work extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Work">
                <section className="my-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8 text-center">
                                <JumbotronTitleBox title="Work" paragraph="Discover stories of successes that we achieve together with our business partners. Maybe yours will be next?" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="case-studies">
                    <Jumbotron>
                        <TitleBox logo="" title="Creating e-commerce story of content" button="Learn more" route="/work/1" />
                    </Jumbotron>
                    <Jumbotron>
                        <TitleBox logo="" title="Innowacyjne podejście do rozwoju kompetencji pracowników" button="Learn more" />
                    </Jumbotron>
                    <Jumbotron>
                        <TitleBox logo="" title="Creating e-commerce story of content" button="Learn more" />
                    </Jumbotron>
                    <Jumbotron>
                        <TitleBox logo="" title="Innowacyjne podejście do rozwoju kompetencji pracowników" button="Learn more" />
                    </Jumbotron>
                    <Jumbotron>
                        <TitleBox logo="" title="Creating e-commerce story of content" button="Learn more" />
                    </Jumbotron>
                </section>
                <LetsWork />
            </div>
        );
    }
}

export default Work;