import React, { Component } from 'react';
import './CookieBar.scss';
import PrivacyPolicy from '../../files/2019_06_19_polityka_prywatnosci.pdf';

class CookieBar extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className={"CookieBar fixed-bottom " + (!this.props.context.state.cookieBar ? "hidden" : "")}>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-9 col-xl-10 my-2">
                            {this.props.context.state.language === 'en' ? (
                                <small>
                                    This site uses cookies to provide services in accordance with the Privacy Policy. The conditions for storing or accessing cookie files can be managed in your browser.
                                </small>
                                ) : (
                                <small>
                                    Strona wykorzystuje pliki Cookies w celu realizacji usług i zgodnie z <a href={PrivacyPolicy} target="_blank">Polityką Prywatności</a>. Warunkami przechowywania lub dostępu do plików cookies możesz zarządzać w&nbsp;Twojej przeglądarce.
                                </small>
                            )}
                        </div>
                        <div className="col-12 col-lg-3 col-xl-2">
                            <button className="btn btn-coral w-100 my-2" onClick={this.props.context.closeCookieBar}>
                            {this.props.context.state.language === 'en' ? (
                                <span>
                                    Got it
                                </span>
                                ) : (
                                <span>
                                    Rozumiem
                                </span>
                            )}
                                
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CookieBar;