import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../components/counter-item/CounterItem";

import BannerImg from "../images/research-process/analysis/banner-img.svg";

import Image from "../images/research-process/analysis/img.jpg";

import ResearchProcessSecondaryNav from "../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../components/tracking/Tracking";

class Analysis extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Analysis">
                <ResearchProcessSecondaryNav active="4"/>
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Analiza i raport badawczy" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">ETAP 4</h6>
                                <JumbotronTitleBox title="Analiza i&nbsp;raport badawczy" paragraph="Zwizualizujemy rezultaty badań, wyeksportujemy twarde dane i&nbsp;damy Ci możliwość dogłębnej analityki."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport" className="active"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc mt-xl coral">ETAP 4</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h4>Łączymy zebrane dane</h4>
                                    <p className="mb-sm introduction">
                                        Zbieramy dane, porównujemy je i&nbsp;wyciągamy wnioski. Integrowanie narzędzi
                                        badawczych pozwala na&nbsp;opracowanie wyników stanowiących wyczerpujące odpowiedzi
                                        na&nbsp;Twoje pytania.
                                    </p>
                                    <h4>Tworzymy rzetelne raporty</h4>
                                    <p className="mb-sm introduction">
                                        Na&nbsp;podstawie wyników badań tworzymy raport badawczy, który wraz
                                        z&nbsp;wizualizacjami dostarczamy do&nbsp;klienta. W&nbsp;zależności
                                        od&nbsp;potrzeb, oferujemy również możliwość eksportu surowych danych.
                                    </p>
                                    <h4>Wpływamy na Twój rozwój</h4>
                                    <p className="mb-sm introduction">
                                        Raport będzie zawierać rekomendację zmian optymalizujących działania
                                        marketingowe online, umożliwiających osiągnięcie zamierzonych celów
                                        sprzedażowych oraz&nbsp;wizerunkowych.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

            </div>
        );
    }
}

export default Analysis;