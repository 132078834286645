import React, { Component } from 'react';
import TitleBox from "../../components/title-box/TitleBox";
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../../components/counter-item/CounterItem";

import BannerImg from "../../images/research-process/method-linking/banner-img.svg";

import Image from "../../images/research-process/method-linking/img.jpg";

import ResearchProcessSecondaryNav from "../../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../../components/tracking/Tracking";

class MethodLinking extends Component {

    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this)
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="MethodLinking">
                <ResearchProcessSecondaryNav active="2" language="en" />
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Combining research methods" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">ETAP 2</h6>
                                <JumbotronTitleBox title="Combining research methods" paragraph="We will select equipment, software and research tools tailored for your business needs."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych" className="active"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc coral">ETAP 2</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h4>We choose the methodology precisely</h4>
                                    <p className="mb-sm introduction">
                                        We use many research methods and techniques to combine them simultaneously.
                                        Hardware, software and research tools are adjusted individually to each client.
                                        Tell us your needs and we will choose the methodology so that you can get the
                                        answers, the implementation of which will translate into real profits.
                                    </p>
                                    <h4>We get real data</h4>
                                    <p className="mb-sm introduction">
                                        In the research environment we created, we check the recipients' reactions,
                                        their feelings and opinions related to the topic of the research. We obtain
                                        reliable results that determine the accuracy of the analyzed project.
                                    </p>
                                    <h4>We are developing the research process</h4>
                                    <p className="mb-sm introduction">
                                        An integrated approach enables a deeper understanding of human behavior.
                                        We use the best measurements to obtain reliable results and constantly
                                        complement and expand the current scope of research procedures.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="pt-sm pb-l next-section">
                    <div className="container">
                        <Link to="/en/research-process/collecting-data" onClick={this.scrollTop}>
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-2">

                                    <h6 className="uc coral">Go to step 3</h6>
                                    <TitleBox title="Collecting data" paragraph="Gathering and combining results with stimuli" />

                            </div>
                            <div className="col-12 col-lg-2 align-self-end">
                                <div className="rounded-arrow"></div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>

            </div>
        );
    }
}

export default MethodLinking;