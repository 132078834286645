import React, {Component} from 'react';
import {BrowserRouter as Router, Link} from 'react-router-dom';
import imageAuthor from '../images/blog/maciej_raczak.jpg';
import {initGA, PageView} from "../components/tracking/Tracking";
import PlaceholderImage from '../images/af_lab_miniatura_fb_01.png';
import Loader from '../components/loader/Loader';

class Blog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            posts: [],
            dataRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/posts",
            categoryRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/categories",
            //authorsRoute: "http://lab.af.agency/wordpress/wp-json/wp/v2/users/",
            mediaRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/media",
            categories: [],
            authors: [],
            images: [],
            fetchComplete: {
                posts: false,
                categories: false,
                authors: false,
                images: false
            }
        };
        this.scrollTop = this.scrollTop.bind(this);
    }

    componentDidMount() {
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(posts => this.setState((prevState, props) => {
                let fetchComplete = Object.assign({}, prevState.fetchComplete);
                fetchComplete.posts = true;
                let tmpPosts = [];
                posts.map(post => {
                    let tmpPost =  {
                        title: post.title.rendered,
                        key: post.id,
                        id: post.id,
                        //author: post.author,
                        content: post.content.rendered,
                        excerpt: post.excerpt.rendered,
                        categories: post.categories,
                        images: post.acf.photo,
                    };
                    if (post.acf.language) {
                        if (post.acf.language.toLowerCase() === 'pl') {
                            tmpPosts.push(tmpPost);
                        }
                    } else {
                        tmpPosts.push(tmpPost);
                    }
                })
                return {
                    posts: tmpPosts,
                    fetchComplete: fetchComplete
                };
            }));
        fetch(this.state.categoryRoute)
            .then(res => res.json())
            .then(data => {
                this.setState((prevState, props) => {
                    let fetchComplete = Object.assign({}, prevState.fetchComplete);
                    fetchComplete.categories = true;
                    return {
                        categories: data,
                        fetchComplete: fetchComplete
                    };
                })
            });
        // fetch(this.state.authorsRoute)
        //     .then(res => res.json())
        //     .then(data => {
        //         this.setState((prevState, props) => {
        //             let fetchComplete = Object.assign({}, prevState.fetchComplete);
        //             fetchComplete.authors = true;
        //             return {
        //                 authors: data,
        //                 fetchComplete: fetchComplete
        //             };
        //         })
        //     });
        fetch(this.state.mediaRoute)
            .then(res => res.json())
            .then(data => {
                this.setState((prevState, props) => {
                    let fetchComplete = Object.assign({}, prevState.fetchComplete);
                    fetchComplete.images = true;
                    return {
                        images: data,
                        fetchComplete: fetchComplete
                    };
                })
            });
        initGA('UA-142608572-1');
        PageView();
    }

    mapSection(post) {
        if (post.acf.language) {
            if (post.acf.language.toLowerCase() === 'pl') {
                return {
                    title: post.title.rendered,
                    key: post.id,
                    id: post.id,
                    //author: post.author,
                    content: post.content.rendered,
                    excerpt: post.excerpt.rendered,
                    categories: post.categories,
                    images: post.acf.photo,
                };
            }
        } else {
            return {
                title: post.title.rendered,
                key: post.id,
                id: post.id,
                //author: post.author,
                content: post.content.rendered,
                excerpt: post.excerpt.rendered,
                categories: post.categories,
                images: post.acf.photo,
            };
        }
    }

    scrollTop() {
        window.scrollTo(0, 0);
        this.setState(state => ({
            invertedNav: false
        }))
    }




    render() {
        const primaryPost = this.state.posts[0];

        if (primaryPost && this.state.categories.length > 0 && this.state.images.length > 0) {

            //const primaryPostAuthorID = [this.state.posts[0].author];
            const idToLookForInPrimaryPost = primaryPost.categories;

            //extract object from array of objects with specific id, that is known to u
            const resultPrimaryPost = idToLookForInPrimaryPost.map(id => this.state.categories.find(o => o.id === id).name);

            //const authorName = primaryPostAuthorID.map(id => this.state.authors.find(o => o.id === id).name);

            const imageLinkToPrimaryPost = this.state.posts[0].images;

            return (
                <div className="Blog">
                    <section className="pt-xl pb-md-4 pb-lg-5 pb-xl-5 position-relative">
                        {/*<div className={"loader " + ((this.state.fetchComplete.posts && this.state.fetchComplete.authors && this.state.fetchComplete.categories && this.state.fetchComplete.images) ? "hidden" : "shown")}>*/}
                            {/*<div className="spinner">*/}
                                {/*<div className="double-bounce1"></div>*/}
                                {/*<div className="double-bounce2"></div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                        <div className="container">
                            <Link to={"/blog/post/" + primaryPost.id} onClick={this.scrollTop}>
                                <div className="row align-items-center justify-content-center">
                                    <div className="col-lg-9 col-xl-7">
                                        <img src={imageLinkToPrimaryPost ? imageLinkToPrimaryPost : PlaceholderImage} className="img-fluid mx-auto d-block" alt="zdjecie do wpisu"/>
                                    </div>
                                    <div
                                        className="col-lg-9 col-xl-5 d-flex flex-column justify-content-between text-container">
                                        <h4>
                                            {resultPrimaryPost.map((el, index) =>
                                                <span key={index}>{el} </span>
                                            )}
                                        </h4>
                                        <h2 dangerouslySetInnerHTML={{__html: primaryPost.title}}></h2>
                                        <div dangerouslySetInnerHTML={{__html: primaryPost.excerpt}}></div>
                                        <div className="container author">
                                            <div className="row align-items-center">
                                                <img src={imageAuthor} alt="" className="img-fluid mr-3"/>
                                                <div>
                                                    <p className="author-name">Maciej Raczak</p>
                                                    <div className="author-company">R&D Head, AF.Agency</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </section>

                    <div className="container pb-5 pt-5">
                        <div className="row">
                            <div className="col-12">
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>

                    <section className="posts pb-m">
                        <div className="container">
                            <div className="row justify-content-center">
                                {this.state.posts.slice(1).map((post, i) =>
                                    <div key={i} className="col-md-12 col-lg-6 col-xl-4">
                                        <Link to={"/blog/post/" + post.id} onClick={this.scrollTop}>
                                            <div className="post-card d-flex flex-column">
                                                <img src={post.images ? post.images : PlaceholderImage} className="card-img-top" alt="blogpost1"/>
                                                <h4 className="category mt-4">
                                                    {
                                                        post.categories.map(id => this.state.categories.find(o => o.id === id).name + ' ')

                                                    }
                                                </h4>
                                                <h5 className="card-title mt-4" dangerouslySetInnerHTML={{__html: post.title}}></h5>
                                                <div className="card-title mt-4"
                                                     dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
                                                <div className="container author mt-auto">
                                                    <div className="row align-items-center">
                                                        <img src={imageAuthor} alt="" className="img-fluid mr-3"/>
                                                        <div>
                                                            <p className="author-name">
                                                                {/*{*/}
                                                                    {/*this.state.authors.find(o => o.id === post.author).name*/}
                                                                {/*}*/}
                                                                Maciej Raczak
                                                            </p>
                                                            <div className="author-company">R&D Head, AF.Agency
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="container pt-sm pb-sm">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="line"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                            {this.state.posts.length < 1 &&
                            (
                                <h4 className="text-center pb-l">Jeszcze nie ma więcej wpisów.</h4>
                            )
                            }

                        </div>
                    </section>
                </div>
            );
        } else {
            return (
                <Loader fetchComplete={this.state.fetchComplete.posts && this.state.fetchComplete.categories && this.state.fetchComplete.images} />
            )
        }

    }
}

export default Blog;
