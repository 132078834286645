import React, { Component } from 'react';
import TitleBox from "../components/title-box/TitleBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import Jumbotron from "../components/jumbotron/Jumbotron";
import LetsWork from "../components/lets-work/LetsWork";
import LogoBox from "../components/logo-box/LogoBox";
import CounterItem from "../components/counter-item/CounterItem";

class WorkItem extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="WorkItem">
                <Jumbotron>
                    <JumbotronTitleBox title="Creating e-commerce story of content" paragraph="Together with the Amica brand, we create a rich content e-commerce offer, which systematic enlargement has enabled us to constantly optimize the implementation process. Thanks to the attractive form, we managed to produce and implement high-quality rich content ahead of the brand competition. Engaging internal and external education enabled the increase of employees competences and final customer’s knowledge of the brand and products." />
                </Jumbotron>

                <section className="counter py-xl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-4">
                                <CounterItem title="10 000+" paragraph="Product Cards"/>
                            </div>
                            <div className="col-12 col-lg-4">
                                <CounterItem title="11" paragraph="Product Categories"/>
                            </div>
                            <div className="col-12 col-lg-4">
                                <CounterItem title="15" paragraph="E-commerce Platforms in Poland"/>
                            </div>
                        </div>
                    </div>
                </section>

                <article className="mb-xl">
                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h6 className="uc mt-xl">Challenge</h6>
                                    <h3>Effective brand communication at the point of sale online</h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal"></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <p>
                                        Based on the analysis of consumer insights, the nature of the brand and its previous activities, we have included the Amica e-commerce offer in a visually and substantively involving message, communicated directly on the product card in the online store. Our projects are like a virtual consultant during online shopping – in a transparent and attractive way presents a product, its functions, appearance and how it can make life easier everyday.
                                    </p>
                                    <p>
                                        Each card is a separate, but consistent story about the product, that can be found in texts as well as in graphic elements. That’s how we produced cards for 11 product categories and implemented them in direct cooperation with the 15 strongest e-retailers in Poland.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner mt-xl">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12"></div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h6 className="uc mt-xl">Challenge</h6>
                                    <h3>Development and systematization of product cards production</h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal"></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <p>
                                        Over time, we have begun optimizing the card production process and start creating ready-made modules that organize the card layout and, at the same time, remain flexible enough that their distribution can be adapted to all product categories. Along with the development of the brand and the e-commerce market, we modified the content of the cards, creating further variants of the modules, enriching our offer with interactive modules, even more engaging the consumer.
                                    </p>
                                    <p>
                                        Our activities have enabled us to develop a tool that allows the implementation of rich content product cards on a large scale – Shoplify.co. This tool systematized the selection of modules and gave freedom to use them for any product category in each industry.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h6 className="uc mt-xl">Challenge</h6>
                                    <h3>Shortening the customer’s journey to the right purchasing decision</h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal"></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <p>
                                        By expanding the content of product cards in online stores, we took care not only of the appropriate display of products, but also of maintaining a consistent brand image in each of them. We have designed and implemented Amica shop-in-shop websites, ordering information about the brand and its offer and created educating content that is a value which develops an expert brand image.
                                    </p>
                                    <p>
                                        Readable and consistent with the brand character landing pages present the offer in an interactive way. In order to facilitate the acquisition of relevant informations about products, the projects were based on the UX design in fully responsive versions, adapted to each type of carrier.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="banner mt-xl">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12"></div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h6 className="uc mt-xl">Challenge</h6>
                                    <h3>Education of a modern e-commerce client</h3>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal"></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <p>
                                        In order to increase the efficiency of the message contained in rich content materials, we have expanded it to an educational content that strengthens the brand’s expert position. On the shop-in-shop website we have placed an interactive Amica kitchen configurator, which helps consumer to learn more about the brand’s offer and possible variants of the choice, making it easier for him to make a decision.
                                    </p>
                                    <p>
                                        The ability to visualize the equipment arrangement and its presentation directly in the kitchen space underlines the transparency, modernity and openness of the brand, which understands consumers’ needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>
                </article>

                <Jumbotron>
                    <TitleBox title="Innowacyjne podejście do rozwoju kompetencji pracowników" button="Learn more" />
                </Jumbotron>
                <LetsWork />
            </div>
        );
    }
}

export default WorkItem;