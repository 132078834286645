import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../components/contact-form/ContactForm";

import BannerImg from "../images/research/atl-btl/banner-img.svg";

import { Link, animateScroll as scroll } from 'react-scroll';

import ResearchSecondaryNav from "../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class AtlBtlResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="AtlBtlResearch">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>AF.lab - badania materiałów reklamowych BTL i ATL</title>
                    <meta property="og:title" content="AF.lab - badania materiałów reklamowych BTL i ATL" />
                    <meta name="description" content="Badanie sentymentu dla materiałów reklamowych video i ATL, ocena atrakcyjności materiałów reklamowych BTL"/>
                    <meta property="og:description" content="Badanie sentymentu dla materiałów reklamowych video i ATL, ocena atrakcyjności materiałów reklamowych BTL" />
                </Helmet>
                <ResearchSecondaryNav active="4"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="ATL i BTL" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">ATL i BTL</h5>
                                <JumbotronTitleBox title="Odczytanie emocji odbiorcy" paragraph="Dogłębna analiza procesów wzbogacona o wywiady pogłębione tokompleksowe, wielopłaszczyznowe badanie, które pozwala określić sentyment względem danego materiału." />
                                <Link to="ContactForm" smooth={true}><button className="btn btn-primary btn-violet mt-s">Zamów badania</button></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Wybierz badania</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie sentymentu dla&nbsp;materiałów video
                                    </h4>
                                    <p className="large">
                                        Sprawdzamy czas skupienia wzroku oraz ilość i&nbsp;częstotliwość fiksacji,
                                        co&nbsp;przekłada się na&nbsp;zauważanie danego obszaru. Dzięki dodaniu badania
                                        przewodności skóry, jesteśmy w&nbsp;stanie określić, czy&nbsp;dany materiał wywołuje emocje oraz wskazać ich natężenie.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Ocena atrakcyjności materiału BTL
                                    </h4>
                                    <p className="large">
                                        Analiza eyetrackingowa (dotyczyć może POS-u, folderu, opakowania) oparta o&nbsp;wizualizację
                                        na&nbsp;ekranie komputera lub na&nbsp;realnym produkcie z&nbsp;wykorzystaniem technologii przenośnej
                                        oraz praca z&nbsp;grupą focusową.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Opracowanie indywidualnego procesu badawczego
                                    </h4>
                                    <p className="large">
                                        Potrzebujesz indywidualnie dopasowanego procesu badawczego, by&nbsp;zbadać swoje
                                        działania w&nbsp;obszarze ATL i&nbsp;BTL? Nasz zespół zaprojektuje badania precyzyjnie
                                        dostosowane do&nbsp;Twoich potrzeb. Wypełnij formularz zgłoszeniowy, a&nbsp;my skontaktujemy się z&nbsp;Tobą.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{backgroundImage: 'url(' + ContactFormBg + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'left 10% bottom 30%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Zostaw swoje dane, a&nbsp;przedstawimy Ci plan badań</h2>
                                <p className="introduction">
                                    Napisz do&nbsp;nas i&nbsp;opowiedz o&nbsp;swoich potrzebach, a&nbsp;wrócimy z&nbsp;propozycją współpracy badawczej, która zaowocuje rozkwitem Twojego biznesu.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default AtlBtlResearch;