import React, { Component } from 'react';
import './List.scss';

class List extends Component {
    render() {
        const items = this.props.list;
        const listItems = items.map((item, index) =>
            <li key={index}><span dangerouslySetInnerHTML={{__html: item}}></span></li>
        );
        return (
            <ul className="CheckedList">
                {listItems}
            </ul>
        );
    }
}

export default List;