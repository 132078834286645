import React, {Component} from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import {PageView, initGA} from "./components/tracking/Tracking";
import { MyProvider, MyContext } from './context.jsx';

import Navigation from './components/nav/Navigation.js';
import './App.scss';
import ReactGA from 'react-ga';
import Index from "./pages/Index";
import Laboratory from "./pages/Laboratory";
import Work from "./pages/Work";
import WorkItem from "./pages/WorkItem";
import Culture from "./pages/Culture";
import Careers from "./pages/Careers";
import CareersItem from "./pages/CareersItem";
import Contact from "./pages/Contact";
import Research from "./pages/Research";
import Footer from "./components/footer/Footer";
import ResearchProcess from "./pages/ResearchProcess";
import ResearchTechniques from "./pages/ResearchTechniques";
import ResearchDesign from "./pages/ResearchDesign";
import MethodLinking from "./pages/MethodLinking";
import GatheringData from "./pages/GatheringData";
import Analysis from "./pages/Analysis";
import BecomeARespondent from "./pages/BecomeARespondent";
import OrderResearch from "./pages/OrderResearch";
import ElearningResearch from "./pages/ElearningResearch";
import EcommerceResearch from "./pages/EcommerceResearch";
import AtlBtlResearch from "./pages/AtlBtlResearch";
import DigitalResearch from "./pages/DigitalResearch";
import Blog from "./pages/Blog";
import BlogItem from "./pages/BlogItem";

import IndexEn from "./pages/en/Index";
import LaboratoryEn from "./pages/en/Laboratory";
import ContactEn from "./pages/en/Contact";
import ResearchEn from "./pages/en/Research";
import ResearchProcessEn from "./pages/en/ResearchProcess";
import ResearchTechniquesEn from "./pages/en/ResearchTechniques";
import ResearchDesignEn from "./pages/en/ResearchDesign";
import MethodLinkingEn from "./pages/en/MethodLinking";
import GatheringDataEn from "./pages/en/GatheringData";
import AnalysisEn from "./pages/en/Analysis";
import BecomeARespondentEn from "./pages/en/BecomeARespondent";
import OrderResearchEn from "./pages/en/OrderResearch";
import ElearningResearchEn from "./pages/en/ElearningResearch";
import EcommerceResearchEn from "./pages/en/EcommerceResearch";
import AtlBtlResearchEn from "./pages/en/AtlBtlResearch";
import DigitalResearchEn from "./pages/en/DigitalResearch";
import BlogEn from "./pages/en/Blog";
import BlogItemEn from "./pages/en/BlogItem";


import CookieBar from "./components/cookie-bar/CookieBar";
import NotFound from "./pages/NotFound";

export class App extends Component {
    constructor() {
        super();
        this.handleScroll = this.handleScroll.bind(this);
    }

    handleScroll() {
        // const currentScrollPos = window.pageYOffset;
        //
        // let animated = document.getElementsByClassName('anim');
        //
        // for (let anim of animated) {
        //     if ((currentScrollPos + window.innerHeight) >= anim.offsetTop) {
        //         anim.classList.add('active');
        //     }
        // }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
        initGA('UA-142608572-1');
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

  render() {
      return (
          <div className="App">
              <MyProvider>
                  <Router>
                      <MyContext.Consumer>
                          {(context) => (
                              <React.Fragment>
                                  <Navigation context={context} />

                                  <Route path="/" exact component={Index} />
                                  <Route path="/laboratorium" exact component={Laboratory} />
                                  <Route path="/oferta-badan" exact component={Research} />
                                  <Route path="/proces-badawczy" exact component={ResearchProcess} />
                                  <Route path="/proces-badawczy/projektowanie-badan" exact component={ResearchDesign} />
                                  <Route path="/proces-badawczy/technika-laczenia-metod-badawczych" exact component={MethodLinking} />
                                  <Route path="/proces-badawczy/zbieranie-danych" exact component={GatheringData} />
                                  <Route path="/proces-badawczy/analiza-i-raport" exact component={Analysis} />
                                  <Route path="/research-techniques" exact component={ResearchTechniques} />
                                  <Route path="/research-design" exact component={ResearchDesign} />
                                  <Route path="/zostan-respondentem" exact component={BecomeARespondent} />
                                  <Route path="/zamow-badania" exact component={OrderResearch} />
                                  <Route path="/oferta-badan/badania-z-obszaru-elearning" exact component={ElearningResearch} />
                                  <Route path="/oferta-badan/badania-z-obszaru-ecommerce" exact component={EcommerceResearch} />
                                  <Route path="/oferta-badan/badania-z-obszaru-btl-atl" exact component={AtlBtlResearch} />
                                  <Route path="/oferta-badan/badania-z-obszaru-digital" exact component={DigitalResearch} />
                                  <Route path="/work" exact component={Work} />
                                  <Route path="/work/1" exact component={WorkItem} />
                                  <Route path="/culture" component={Culture} />
                                  <Route path="/careers" exact component={Careers} />
                                  <Route path="/careers/1" exact component={CareersItem} />
                                  <Route path="/kontakt" component={Contact} />
                                  <Route path="/blog" exact component={Blog} />
                                  <Route path="/blog/post/:id" component={BlogItem} />


                                  <Route path="/en/" exact component={IndexEn} />
                                  <Route path="/en/lab" exact component={LaboratoryEn} />
                                  <Route path="/en/research-offer" exact component={ResearchEn} />
                                  <Route path="/en/research-process" exact component={ResearchProcessEn} />
                                  <Route path="/en/research-process/designing-research" exact component={ResearchDesignEn} />
                                  <Route path="/en/research-process/combining-methods" exact component={MethodLinkingEn} />
                                  <Route path="/en/research-process/collecting-data" exact component={GatheringDataEn} />
                                  <Route path="/en/research-process/analysis-and-report" exact component={AnalysisEn} />
                                  <Route path="/en/research-techniques" exact component={ResearchTechniquesEn} />
                                  <Route path="/en/research-design" exact component={ResearchDesignEn} />
                                  <Route path="/en/become-a-respondent" exact component={BecomeARespondentEn} />
                                  <Route path="/en/order-research" exact component={OrderResearchEn} />
                                  <Route path="/en/research-offer/elearning-research" exact component={ElearningResearchEn} />
                                  <Route path="/en/research-offer/ecommerce-research" exact component={EcommerceResearchEn} />
                                  <Route path="/en/research-offer/btl-atl-research" exact component={AtlBtlResearchEn} />
                                  <Route path="/en/research-offer/digital-research" exact component={DigitalResearchEn} />
                                  <Route path="/en/contact" component={ContactEn} />
                                  <Route path="/en/blog" exact component={BlogEn} />
                                  <Route path="/en/blog/post/:id" component={BlogItemEn} />

                                  {/*<Route path="*" component={NotFound} />*/}

                                  <Footer context={context} />

                                  {/*<CookieBar context={context} />*/}
                              </React.Fragment>
                          )}
                      </MyContext.Consumer>

                  </Router>
              </MyProvider>
          </div>
      );
  }
}

export default App;
