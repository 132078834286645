import React, {Component} from 'react';

import image from '../images/blog/agency.jpg';
import imageAuthor from "../images/blog/maciej_raczak.jpg";
import {initGA, PageView} from "../components/tracking/Tracking";
import Loader from "../components/loader/Loader";


class BlogItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            post: {},
            dataRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/posts/" + props.match.params.id,
            //authorsRoute: "http://lab.af.agency/wordpress/wp-json/wp/v2/users/",
            categoriesRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/categories",
            postID: props.match.params.id,
            categories: [],
            authors: [],
            fetchComplete: {
                post: false,
                category: false,
                author: false
            }
        }
    }


    componentDidMount() {
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(post => this.setState((prevState, props) => {
                // let author = post.author == 1 ? 'AF.lab' : 'Nieznany';
                let fetchComplete = Object.assign({}, prevState.fetchComplete);
                fetchComplete.post = true;
                return {
                    post: {
                        title: post.title.rendered,
                        // author: post.author,
                        content: post.content.rendered,
                        categories: post.categories,
                        imageHref: post.acf.photo,
                    },
                    fetchComplete: fetchComplete
                };
            }));
        fetch(this.state.categoriesRoute)
            .then(res => res.json())
            .then(data => {
                this.setState((prevState, props) => {
                    let fetchComplete = Object.assign({}, prevState.fetchComplete);
                    fetchComplete.category = true;
                    return {
                        categories: data,
                        fetchComplete: fetchComplete
                    };
                })
            });
        // fetch(this.state.authorsRoute)
        //     .then(res => res.json())
        //     .then(data => {
        //         this.setState((prevState, props) => {
        //             let fetchComplete = Object.assign({}, prevState.fetchComplete);
        //             fetchComplete.author = true;
        //             return {
        //                 authors: data,
        //                 fetchComplete: fetchComplete
        //             };
        //         })
        //     });
        initGA('UA-142608572-1');
        PageView();
    }

    // mapSection(post){
    //     let author = post.author == 1 ? 'AF.lab' : 'Nieznany';
    //     return {
    //         title:  post.title.rendered,
    //         author: author,
    //         content:  post.content.rendered
    //     }
    // }

    render() {
        // You can use them as regular CSS styles

        let text = this.state.post.content;

        const idsToLookFor = this.state.post.categories;


        const style = {
            width: '1116px',
        };

        if (idsToLookFor && this.state.categories.length > 0) {
            const categoriesOfPost = idsToLookFor.map(id => this.state.categories.find(o => o.id === id).name);

            return (
                <div className="BlogItem">
                    <section className="posts mt-sm">
                        <div className="container">
                            <article className="post" id={this.postID}>
                                <div className="row justify-content-center">
                                    <div className="col-12 col-lg-6">
                                        <h4>{categoriesOfPost.map((el, index) =>
                                            <span key={index}>{el} </span>
                                        )}</h4>
                                        <h2 dangerouslySetInnerHTML={{__html: this.state.post.title}}></h2>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="mx-auto">
                                        <img style={style} className="img-fluid mt-m" src={this.state.post.imageHref ? this.state.post.imageHref : undefined} alt=""/>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-5">
                                    <div className="col-12 col-lg-6">
                                        <div className="container author mb-4">
                                            <div className="row align-items-center">
                                                <img src={imageAuthor} alt="" className="img-fluid mr-3"/>
                                                <div>
                                                    <p className="author-name">
                                                        {/*{*/}
                                                            {/*this.state.authors.find(o => o.id === this.state.post.author).name*/}
                                                        {/*}*/}
                                                        Maciej Raczak
                                                    </p>
                                                    <div className="author-company">R&D Head, AF.Agency</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-content" dangerouslySetInnerHTML={{__html: text}}></div>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </section>
                    <div className="container pb-m">
                        <div className="row">
                            <div className="col-12">
                                <div className="line"></div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="container">*/}
                    {/*    <div className="row justify-content-center">*/}
                    {/*        <h2>Powiązane artykuły</h2>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            );


        } else {
            return (
                <Loader fetchComplete={this.state.fetchComplete.category && this.state.fetchComplete.post}/>
            );
        }

        // //extract object from array of objects with specific id, that is known to u

        // console.log(categoriesOfPost);

    }
}

export default BlogItem;
