import React, { Component } from 'react';
import './AccordionItem.scss';
import AccordionHead from "../accordion-head/AccordionHead";
import AccordionBody from "../accordion-body/AccordionBody";

class AccordionItem extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        this.props.handleOpen(this.props.index);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className={"AccordionItem " + (this.props.isOpened ? "open" : "")} onClick={this.handleClick} >
                <div className="accordion-number">
                    0{this.props.index + 1}
                </div>
                <div className="accordion-content">
                    <AccordionHead>
                        <h5>{this.props.label}</h5>
                    </AccordionHead>
                    <AccordionBody isOpened={this.props.isOpened}>
                        {this.props.children}
                    </AccordionBody>
                </div>
            </div>
        );
    }
}

export default AccordionItem;