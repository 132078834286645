import React, { Component } from 'react';

export const MyContext = React.createContext();

export class MyProvider extends Component {
    state = {
        language: '',
        cookieBar: true
    };

    componentDidMount() {
        if (window.location.pathname.indexOf('/en') > -1) {
            this.setState({
                language: 'en'
            });
            document.title = "AF.lab - research, neuromarketing, consumer motivation"
        } else {
            this.setState({
                language: ''
            });
            document.title = "AF.lab - badania, neuromarketing, motywacje konsumenckie"
        };
        this.checkCookie('cookieBar');
    }

    changeLanguage = (e) => {
        this.setState({
            language: e.target.dataset.language
        });
        setTimeout(() => {
            this.changeDocumentTitle();
        }, 50)
    };

    closeCookieBar = () => {
        this.setState({
            cookieBar: false
        });
        this.setCookie('cookieBar', 'closed', 90);
    };

    changeDocumentTitle = () => {
        if (this.state.language === "en") {
            document.title = "AF.lab - research, neuromarketing, consumer motivation"
        } else {
            document.title = "AF.lab - badania, neuromarketing, motywacje konsumenckie"
        }
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for(var i = 0; i <ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    checkCookie = () => {
        let cookie = this.getCookie('cookieBar');
        if (cookie) {
            this.setState({
                cookieBar: false
            });
        }
    }

    render() {
        return (
            <MyContext.Provider value={{state: this.state, changeLanguage: this.changeLanguage, closeCookieBar: this.closeCookieBar}}>
                {this.props.children}
            </MyContext.Provider>
        )
    }
}