import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import LetsWork from "../components/lets-work/LetsWork";
import LogoBox from "../components/logo-box/LogoBox";
import List from "../components/list/List";
import TitleBox from "../components/title-box/TitleBox";

import {ReactComponent as EyeTracking} from "../images/research/eye-tracking.svg";
import {ReactComponent as CsAndGsr} from "../images/research/cs-and-gsr.svg";
import {ReactComponent as EegAndQeeg} from "../images/research/eeg-and-qeeg.svg";
import {ReactComponent as Observation} from "../images/research/observation.svg";


import Cooperation1 from '../images/index/digital.svg';
import Cooperation2 from '../images/index/elearning.svg';
import Cooperation3 from '../images/index/ecommerce.svg';
import Cooperation4 from '../images/index/btl-atl.svg';

import BannerBg from '../images/research/banner-bg.svg';
import ResearchTechniquesBg1 from '../images/research/research-techniques-bg-1.svg';
import ResearchTechniquesBg2 from '../images/research/research-techniques-bg-2.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class Research extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    handleClick() {
        window.scrollTo(0, 0);
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Research">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>AF.lab - oferta badań neuromarketingowych, User Experience, Eye tracking, SC i GSR, EEG i QEEG</title>
                    <meta property="og:title" content="AF.lab - oferta badań neuromarketingowych, User Experience, Eye tracking, SC i GSR, EEG i QEEG" />
                    <meta name="description" content=" Badania biometryczne i neuromarketingowe oraz testy psychofizjologiczne, by uzyskać jak najlepsze rezultaty w przygotowywaniu komunikacji marketingowej, contentu sprzedażowego pod e-commerce oraz metod e-learningu."/>
                    <meta property="og:description" content=" Badania biometryczne i neuromarketingowe oraz testy psychofizjologiczne, by uzyskać jak najlepsze rezultaty w przygotowywaniu komunikacji marketingowej, contentu sprzedażowego pod e-commerce oraz metod e-learningu." />
                </Helmet>


                <section className="py-xl" style={{backgroundImage: 'url(' + BannerBg + ')', backgroundSize: '100% auto', backgroundPosition: 'center top 10%', backgroundRepeat: 'no-repeat'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-6 text-center">
                                <JumbotronTitleBox title="Badania użyteczności, analiza, audyt UX,&nbsp;CX,&nbsp;UI" paragraph="Synergia neuromarketingu, psychofizjologii i biometrii dla rozwoju Twojego e&#8209;commerce, e&#8209;learning oraz rozwiązań digitalowych." />
                            </div>
                        </div>
                    </div>
                </section>




                {/*<section className="py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8 text-center">*/}
                                {/*<JumbotronTitleBox title="Work" paragraph="Discover stories of successes that we achieve together with our business partners. Maybe yours will be next?" />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                {/*<section className="cards py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="digital" paragraph="Zaprojektuj strony internetowe oraz nośniki digitalowe, które będą angażować i zwracać uwagę internautów." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="e-learning" paragraph="Stwórz dla swoich pracowników łatwiej przyswajalne, ciekawsze i skuteczniejsze e-kursy." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="Zostań respondentem" paragraph="Spraw, aby Twoje karty produktowe  dobrze konwertowały w każdym środowisku sklepowym." button="Więcej"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-6">*/}
                                {/*<Card title="Zamów badania" paragraph="Zbadaj wielopoziomowo sentyment względem tworzonych przez Ciebie materiałów." button="Więcej"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <div className="Index">
                    <section className="py-xl position-relative" style={{backgroundColor: '#FAFAFA'}}>
                        <div className="dot-bg"></div>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-lg-8 col-xl-7 text-center">
                                    <TitleBox title="Zakres i obszary usług badawczych" paragraph="Jak możesz wykorzystać wiedzę o&nbsp;motywacji?" />
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="pb-xl" style={{backgroundColor: '#FAFAFA'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/oferta-badan/badania-z-obszaru-digital" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation1} alt="digital" />
                                                <h4>digital</h4>
                                            </div>
                                            <p className="mb-xs"><b>Zaprojektuj strony internetowe oraz nośniki digitalowe, które będą angażować i&nbsp;zwracać uwagę internautów.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badanie UX stron www</li>*/}
                                            {/*<li>testy AB</li>*/}
                                            {/*<li>badanie zaangażowania</li>*/}
                                            {/*<li>badanie atrakcyjności layoutu</li>*/}
                                            {/*</ul>*/}
                                            <List list={['badania UI - dedykowane dla User Interface', 'badania UX - użyteczność stron www', 'testy A/B', 'badanie zaangażowania i&nbsp;atrakcyjności layoutów']} />
                                            <button className="btn btn-primary btn-violet">Więcej <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/oferta-badan/badania-z-obszaru-elearning" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation2} alt="e-learning" />
                                                <h4>e‑learning</h4>
                                            </div>
                                            <p className="mb-xs"><b>Stwórz dla swoich pracowników łatwiej przyswajalne, ciekawsze i&nbsp;skuteczniejsze e‑kursy.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badanie UX materiałów e‑learningowych</li>*/}
                                            {/*<li>analiza efektywności materiałów szkoleniowych</li>*/}
                                            {/*</ul>*/}
                                            <List list={['badanie UX materiałów e‑learningowych', 'analiza efektywności materiałów szkoleniowych']} />
                                            <button className="btn btn-primary btn-violet">Więcej <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/oferta-badan/badania-z-obszaru-ecommerce" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation3} alt="e-commerce" />
                                                <h4>e‑commerce</h4>
                                            </div>
                                            <p className="mb-xs"><b>Spraw, aby Twój sklep i&nbsp;karty produktowe typu rich content dobrze konwertowały w&nbsp;każdym środowisku i&nbsp;sytuacji.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>optymalizacja konwersji</li>*/}
                                            {/*<li>badanie motywacji zakupowych</li>*/}
                                            {/*<li>analiza ścieżki użytkownika</li>*/}
                                            {/*<li>badanie efektywności kart produktowych</li>*/}
                                            {/*</ul>*/}
                                            <List list={['badania użyteczności, analiza i&nbsp;audyt UX i&nbsp;CX', 'optymalizacja konwersji', 'analiza ścieżek użytkownika', 'badania motywacji zakupowych', 'badania efektywności kart produktowych']} />
                                            <button className="btn btn-primary btn-violet">Więcej <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                    <div className="big-link">
                                        <Link to="/oferta-badan/badania-z-obszaru-btl-atl/" onClick={this.handleClick}>
                                            {/*<div className="placeholder mb-xs"></div>*/}
                                            <div className="card-title">
                                                <img src={Cooperation4} alt="BTL/ATL" />
                                                <h4>BTL/ATL</h4>
                                            </div>
                                            <p className="mb-xs"><b>Zbadaj wielopoziomowo sentyment względem tworzonych przez Ciebie materiałów.</b></p>
                                            {/*<ul>*/}
                                            {/*<li>badania sentymentu dla marek i&nbsp;produktów</li>*/}
                                            {/*<li>badania sentymentu dla materiałów wideo</li>*/}
                                            {/*<li>badania zaangażowania</li>*/}
                                            {/*</ul>*/}
                                            <List list={['badania sentymentu dla&nbsp;marek i&nbsp;produktów', 'badania sentymentu dla&nbsp;materiałów wideo', 'badania zaangażowania']} />
                                            <button className="btn btn-primary btn-violet">Więcej <i className="material-icons">arrow_forward</i></button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="row justify-content-center mt-sm">*/}
                                {/*<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">*/}
                                    {/*<button className="btn btn-outline-dark w-100">Idź do strony <i className="material-icons">arrow_forward</i></button>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        </div>
                    </section>
                </div>

                <div className="ResearchTechniques">
                    <section className="py-xl" style={{backgroundImage: 'url(' + BannerBg + ')', backgroundSize: '100% auto', backgroundPosition: 'center top 10%', backgroundRepeat: 'no-repeat'}}>
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-xl-8 text-center">
                                    <JumbotronTitleBox title="Techniki badawcze" paragraph="Łączymy badania biometryczne z neuromarketingowymi oraz testami psychofizjologicznymi, by uzyskać jak najlepsze rezultaty w przygotowywaniu komunikacji marketingowej, contentu sprzedażowego oraz metod e-nauki.<br /><br />Wykorzystujemy autorski system i proces badawczy wypracowany wspólnie  z autorytetami naukowymi, działającymi w sferze neuromarketingu na wiodących uczelniach w Polsce i za granicą. Zaproponowana przez nas forma badań nie była do tej pory stosowana nigdzie na świecie." />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="techniques pb-xl">
                        <div className="container" style={{backgroundImage: 'url(' + ResearchTechniquesBg1 + '), url(' + ResearchTechniquesBg2 + ')', backgroundRepeat: 'no-repeat, no-repeat', backgroundSize: '45% auto, 45% auto', backgroundPosition: 'right top, left bottom 10%'}}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10">
                                    <div className="masonry text-center">
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <EyeTracking />
                                                </div>
                                                <h4>Eye tracking</h4>
                                                <p className="large">Czyli analiza ruch gałek ocznych, która pozwala na dokładne określenie, które miejsca najcelniej przykuwają uwagę użytkowników.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <CsAndGsr />
                                                </div>
                                                <h4>SC i GSR</h4>
                                                <p className="large">Czyli badanie aktywności elektrodermalnej skóry oraz testy psychofizjologiczne, pozwalające na odkrycie prawdziwych opinii i reakcji emocjonalnych.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <EegAndQeeg />
                                                </div>
                                                <h4>EEG i QEEG</h4>
                                                <p className="large">Czyli badanie długości fal mózgowych (elektroencefalografia) oraz mapowanie mózgu dla określenia skuteczności zastosowanych środków.</p>
                                            </div>
                                        </LogoBox>
                                        <LogoBox>
                                            <div className="content">
                                                <div className="mb-sm">
                                                    <Observation />
                                                </div>
                                                <h4>Obserwacje i ankiety</h4>
                                                <p className="large">Czyli badania ankietowe oraz wywiady pogłębione, które stanowią uzupełnienie wszystkich technik.</p>
                                            </div>
                                        </LogoBox>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="container mt-l">
                            <div className="row">
                                <div className="col-12">
                                    <LetsWork />
                                </div>
                            </div>
                        </div>
                    </section>
                </div>



            </div>
        );
    }
}

export default Research;
