import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './ResearchSecondaryNav.scss'; // Tell Webpack that Button.js uses these styles


class ResearchSecondaryNav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true,
            top: true
        };
        this.scrollTop = this.scrollTop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    scrollTop() {
        window.scrollTo(0, 0);
        this.handleScroll();
    }

    handleScroll(ev) {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;
        const top = currentScrollPos < 10;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible,
            top
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <nav className={"py-xs secondary-nav fixed-top " + (!this.state.visible ? "navbar-hidden " : " ") + (!this.state.top ? "with-border " : " ")}>
                <div className="container">
                    <div className="row">
                        {this.props.language === 'en' ? (
                            <div className="col-12 text-center d-flex align-items-center justify-content-between">
                                <Link to="/en/research-offer/digital-research" onClick={this.scrollTop} className={this.props.active === "1" ? "active" : ""}><div className="bullet"></div><b>digital</b></Link>

                                <Link to="/en/research-offer/elearning-research" onClick={this.scrollTop} className={this.props.active === "2" ? "active" : ""}><div className="bullet"></div><b>e-learning</b></Link>

                                <Link to="/en/research-offer/ecommerce-research" onClick={this.scrollTop} className={this.props.active === "3" ? "active" : ""}><div className="bullet"></div><b>e-commerce</b></Link>

                                <Link to="/en/research-offer/btl-atl-research" onClick={this.scrollTop} className={this.props.active === "4" ? "active" : ""}><div className="bullet"></div><b>BTL/ATL</b></Link>
                            </div>
                        ) : (
                            <div className="col-12 text-center d-flex align-items-center justify-content-between">
                                <Link to="/oferta-badan/badania-z-obszaru-digital" onClick={this.scrollTop} className={this.props.active === "1" ? "active" : ""}><div className="bullet"></div><b>digital</b></Link>

                                <Link to="/oferta-badan/badania-z-obszaru-elearning" onClick={this.scrollTop} className={this.props.active === "2" ? "active" : ""}><div className="bullet"></div><b>e-learning</b></Link>

                                <Link to="/oferta-badan/badania-z-obszaru-ecommerce" onClick={this.scrollTop} className={this.props.active === "3" ? "active" : ""}><div className="bullet"></div><b>e-commerce</b></Link>

                                <Link to="/oferta-badan/badania-z-obszaru-btl-atl" onClick={this.scrollTop} className={this.props.active === "4" ? "active" : ""}><div className="bullet"></div><b>BTL/ATL</b></Link>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        );
    }
}

export default ResearchSecondaryNav;