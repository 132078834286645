import React, { Component } from 'react';
import TitleBox from "../../components/title-box/TitleBox";
import LetsWork from "../../components/lets-work/LetsWork";
import Card from "../../components/card/Card";

import ResearchProcessBg from '../../images/research-process/banner-bg.svg';
import ResearchProcessBg1 from '../../images/research-process/research-process-1.svg';
import ResearchProcessBg2 from '../../images/research-process/research-process-2.svg';

import ResearchProcess1 from '../../images/index/research-design.svg';
import ResearchProcess2 from '../../images/index/method-linking.svg';
import ResearchProcess3 from '../../images/index/gathering-data.svg';
import ResearchProcess4 from '../../images/index/analysis-and-report.svg';
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import {initGA, PageView} from "../../components/tracking/Tracking";

class ResearchProcess extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        return (
            <div className="Index ResearchProcess">

                <section style={{backgroundImage: 'url(' + ResearchProcessBg + ')', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat'}}>
                    <section className="py-xl">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-xl-8 text-center">
                                    <JumbotronTitleBox title="The research process" paragraph="Only four stages separate you from the effective implementation of your business goals." />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pb-xl position-relative overflow-hidden" style={{backgroundImage: 'url(' + ResearchProcessBg1 + '), url(' + ResearchProcessBg2 + ')', backgroundPosition: 'top 10% left 20%, bottom 10% right 10%', backgroundRepeat: 'no-repeat, no-repeat'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <section className="masonry">

                                        <Card title="Designing research" paragraph="We will suggest you the right methodologies and effective research scenarios, thanks to which you will gain reliable knowledge." button="More" route="/en/research-process/designing-research" image={ResearchProcess1} />

                                        <Card title="Combining methods" paragraph="We will select equipment, software and research tools suited for your business needs." button="More" route="/en/research-process/combining-methods" image={ResearchProcess2} />

                                        <Card title="Collecting data" paragraph="We will gather real-time data for you and then combine the results of research with stimuli." button="More" route="/en/research-process/collecting-data" image={ResearchProcess3} />

                                        <Card title="Analysis and research report" paragraph="We will visualize the results of the research, export hard data and give you the opportunity for in-depth analytics." button="More" route="/en/research-process/analysis-and-report" image={ResearchProcess4} />

                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section>
                    <div className="container my-l">
                        <div className="row">
                            <div className="col-12">
                                <LetsWork language="en" />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ResearchProcess;