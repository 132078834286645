import React, { Component } from 'react';
import './AccordionBody.scss';

import {Transition} from 'react-transition-group';

class AccordionBody extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        const duration = 300;

        const defaultStyle = {
            transition: `opacity ${duration}ms ease-in-out`,
            opacity: 0,
        }

        const transitionStyles = {
            entering: { opacity: 1, display: 'block' },
            entered:  { opacity: 1, display: 'block' },
            exiting:  { opacity: 0, display: 'none' },
            exited:  { opacity: 0, display: 'none' },
        };
        // You can use them as regular CSS styles
        return (
            <Transition in={this.props.isOpened}>
                {state => (
                    <div className="AccordionBody" style={{...defaultStyle, ...transitionStyles[state]}}>
                        {this.props.children}
                    </div>
                )}
            </Transition>
        );
    }
}

export default AccordionBody;