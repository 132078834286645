import React, { Component } from 'react';
import ContactBox from "../components/contact-box/ContactBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import MapWrapper from "../components/map/MapWrapper";

import Ujma from "../images/contact/ujma.jpg";
import Bobak from "../images/contact/bobak.jpg";
import {initGA, PageView} from "../components/tracking/Tracking";

class Contact extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Contact">
                <section className="my-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8 text-center">
                                <JumbotronTitleBox title="Pozostańmy w&nbsp;kontakcie" paragraph="Jeśli potrzebujesz kontaktu bezpośredniego, poniżej znajdziesz niezbędne dane." />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="pb-xxl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-md-4 offset-md-2">
                                <ContactBox title="R&D Head" name="Rafał Bobak" phone="+48601401399" email="rafal.bobak@af.agency" image={Bobak}/>
                            </div>
                            <div className="col-12 col-md-4">
                                <ContactBox title="Media" name="Karolina Ujma" phone="+48885453333" email="karolina.ujma@af.agency" image={Ujma}/>
                            </div>
                        </div>
                    </div>
                </section>
                {/*<section className="map">*/}

                {/*</section>*/}
                <MapWrapper />
            </div>
        );
    }
}

export default Contact;
