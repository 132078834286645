import React, { Component } from 'react';
import './LogoBox.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

class LogoBox extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        AOS.init({
            duration : 500
        })
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="LogoBox" data-aos="fade-up">
                {this.props.children}
            </div>
        );
    }
}

export default LogoBox;