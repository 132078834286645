import React, { Component } from 'react';
import './Jumbotron.scss'; // Tell Webpack that Button.js uses these styles
import {ReactComponent as Arrow} from '../../images/ic-keyboard-arrow-down-48px.svg';

class Jumbotron extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <section className="Jumbotron d-flex align-items-center justify-content-center position-relative py-xl">
                <div className="jumbotron-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className={"col-12 text-center " + (this.props.col ? "col-lg-" + this.props.col : "col-lg-8")}>
                                {this.props.children}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="arrow-down text-center"><Arrow /></div>
            </section>
        );
    }
}

export default Jumbotron;