import React, { Component } from 'react';
import TitleBox from "../components/title-box/TitleBox";
import LetsWork from "../components/lets-work/LetsWork";
import Card from "../components/card/Card";

import ResearchProcessBg from '../images/research-process/banner-bg.svg';
import ResearchProcessBg1 from '../images/research-process/research-process-1.svg';
import ResearchProcessBg2 from '../images/research-process/research-process-2.svg';

import ResearchProcess1 from '../images/index/research-design.svg';
import ResearchProcess2 from '../images/index/method-linking.svg';
import ResearchProcess3 from '../images/index/gathering-data.svg';
import ResearchProcess4 from '../images/index/analysis-and-report.svg';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class ResearchProcess extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        return (
            <div className="Index ResearchProcess">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>AF.lab - Neuromarketingowy proces badawczy dla skutecznych celów biznesowych</title>
                    <meta property="og:title" content="AF.lab - Neuromarketingowy proces badawczy dla skutecznych celów biznesowych" />
                    <meta name="description" content="Projektowanie scenariuszy badań, łączenie metod Eye tracking, SC i GSR, EEG i QEEG, zbieranie danych, analiza badań i raporty"/>
                    <meta property="og:description" content="Projektowanie scenariuszy badań, łączenie metod Eye tracking, SC i GSR, EEG i QEEG, zbieranie danych, analiza badań i raporty" />
                </Helmet>
                <section style={{backgroundImage: 'url(' + ResearchProcessBg + ')', backgroundSize: '100% auto', backgroundRepeat: 'no-repeat'}}>
                    <section className="py-xl">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-10 col-xl-8 text-center">
                                    <JumbotronTitleBox title="Proces badawczy" paragraph="Tylko 4&nbsp;etapy dzielą Cię od&nbsp;skutecznej realizacji celów biznesowych." />
                                </div>
                            </div>
                        </div>
                    </section>

                    <section className="pb-xl position-relative overflow-hidden" style={{backgroundImage: 'url(' + ResearchProcessBg1 + '), url(' + ResearchProcessBg2 + ')', backgroundPosition: 'top 10% left 20%, bottom 10% right 10%', backgroundRepeat: 'no-repeat, no-repeat'}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <section className="masonry">

                                        <Card title="Projektowanie badań" paragraph="Zaproponujemy Ci trafne metodologie oraz skuteczne scenariusze badawcze, dzięki którym zyskasz rzetelną wiedzę." button="Więcej" route="/proces-badawczy/projektowanie-badan" image={ResearchProcess1} />

                                        <Card title="Łączenie metod" paragraph="Dobierzemy sprzęt, oprogramowanie i narzędzia badawcze pod kątem Twoich potrzeb biznesowych." button="Więcej" route="/proces-badawczy/technika-laczenia-metod-badawczych" image={ResearchProcess2} />

                                        <Card title="Zbieranie danych" paragraph="Zgromadzimy dla Ciebie dane w czasie rzeczywistym, a następnie połączymy wyniki badań z bodźcami." button="Więcej" route="/proces-badawczy/zbieranie-danych" image={ResearchProcess3} />

                                        <Card title="Analiza i raport badawczy" paragraph="Zwizualizujemy rezultaty badań, wyeksportujemy twarde dane i damy Ci możliwość dogłębnej analityki." button="Więcej" route="/proces-badawczy/analiza-i-raport" image={ResearchProcess4} />

                                    </section>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>

                <section>
                    <div className="container my-l">
                        <div className="row">
                            <div className="col-12">
                                <LetsWork />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default ResearchProcess;