import React, { Component } from 'react';
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import LetsWork from "../../components/lets-work/LetsWork";
import LogoBox from "../../components/logo-box/LogoBox";

import {ReactComponent as Eye} from "../../images/Eye.svg";
import {ReactComponent as Brain} from "../../images/Brain.svg";
import {ReactComponent as Hand} from "../../images/Hand.svg";
import {ReactComponent as Binoculars} from "../../images/Binoculars.svg";
import {initGA, PageView} from "../../components/tracking/Tracking";

class ResearchTechniques extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="ResearchTechniques">
                <section className="py-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8 text-center">
                                <JumbotronTitleBox title="Odkryj nasze nowatorskie techniki badawcze" paragraph="Łączymy badania biometryczne z neuromarketingowymi oraz testami psychofizjologicznymi, by uzyskać jak najlepsze rezultaty w przygotowywaniu komunikacji marketingowej, contentu sprzedażowego oraz metod e-nauki.<br /><br />Wykorzystujemy autorski system i proces badawczy wypracowany wspólnie  z autorytetami naukowymi, działającymi w sferze neuromarketingu na wiodących uczelniach w Polsce i za granicą. Zaproponowana przez nas forma badań nie była do tej pory stosowana nigdzie na świecie" />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="techniques pb-m">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10">
                                <div className="masonry text-center">
                                    <LogoBox>
                                        <div className="content">
                                            <div className="mb-sm">
                                                <Eye />
                                            </div>
                                            <h5>Eye tracking</h5>
                                            <p className="large">Czyli analiza ruch gałek ocznych, która pozwala na dokładne określenie, które miejsca najcelniej przykuwają uwagę użytkowników.</p>
                                        </div>
                                    </LogoBox>
                                    <LogoBox>
                                        <div className="content">
                                            <div className="mb-sm">
                                                <Hand />
                                            </div>
                                            <h5>CS i GSR</h5>
                                            <p className="large">Czyli badanie aktywności elektrodermalnej skóry oraz testy psychofizjologiczne, pozwalające na odkrycie prawdziwych opinii i reakcji emocjonalnych.</p>
                                        </div>
                                    </LogoBox>
                                    <LogoBox>
                                        <div className="content">
                                            <div className="mb-sm">
                                                <Brain />
                                            </div>
                                            <h5>EEG i QEEG</h5>
                                            <p className="large">Czyli badanie długości fal mózgowych (elektroencefalografia) oraz mapowanie mózgu dla określenia skuteczności zastosowanych środków.</p>
                                        </div>
                                    </LogoBox>
                                    <LogoBox>
                                        <div className="content">
                                            <div className="mb-sm">
                                                <Binoculars />
                                            </div>
                                            <h5>Obserwacje i ankiety</h5>
                                            <p className="large">Czyli badania ankietowe oraz wywiady pogłębione, które stanowią uzupełnienie wszystkich technik.</p>
                                        </div>
                                    </LogoBox>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default ResearchTechniques;