import React, { Component } from 'react';
import './EuBanner.scss';
import EuLogo from '../../images/eu.jpg';
import EuLogoEn from '../../images/eu-eng.jpg';
import EuFunds from '../../images/logo_FE_Program_Regionalny_rgb-1.jpg';
import RP from '../../images/znak_barw_rp_poziom_bez_ramki_rgb.png';
import Wlkp from '../../images/samorzad_wlkp.jpg';

class EuBanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            opened: false
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState(state => ({
            opened: !state.opened
        }))
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className={"EuBanner py-xs " + (this.state.opened ? "open" : "")} style={{backgroundColor: 'white'}}>
                <div className="text-center">
                {this.props.language === 'en' ? (
                    <small>
                        <span className="d-none d-xl-inline">AF.AGENCY Sp. z o.o. Ul. Wszystkich Świętych 4A, Poznań implements a project co-financed from European Funds. "Conducting research and development works in order to create optimized product card models for dedicated industries and services".
                        The aim of the project is to raise the innovative potential of AF.AGENCY Sp. z o.o. through the creation of the neuromarketing Laboratory and the improvement of product card models through the use of research results for the needs of entrepreneurs based on the scientific and research equipment acquired as part of the project.
                            The value of the project: PLN 977&nbsp;220.00. Co-financing of the EU project: PLN 685&nbsp;056,00. </span><a href="http://af.agency/funduszeeuropejskie" target="_blank">Read more.</a>
                    </small>
                ) : (
                    <small>
                        <span className="d-none d-xl-inline">AF.AGENCY Sp. z o.o. Ul. Wszystkich Świętych 4A, Poznań realizuje projekt dofinansowany z Funduszy Europejskich pn. „Przeprowadzenie prac badawczo rozwojowych w celu stworzenia zoptymalizowanych modeli kart produktowych dla dedykowanych branż i usług”.
                        Celem projektu jest podniesienie potencjału innowacyjnego AF.AGENCY Sp. z o.o. poprzez utworzenie Laboratorium neuromarketingu oraz udoskonalenie modeli kart produktowych dzięki wykorzystaniu wyników badań na potrzeby przedsiębiorców w oparciu o nabytą w ramach projektu aparaturę naukowo-badawczą.
                            Wartość projektu: 977&nbsp;220,00 PLN. Dofinansowanie projektu z UE: 685&nbsp;056,00 PLN. </span><a href="http://af.agency/funduszeeuropejskie" target="_blank">Więcej informacji.</a>
                    </small>
                )}
                </div>
                <div className="logos">
                    <div><img src={EuFunds} alt="Fundusze Europejskie" /></div>
                    <div><img src={RP} alt="Rzeczpospolita Polska" /></div>
                    <div><img src={Wlkp} alt="Samorząd Województwa Wielkopolskiego" /></div>
                    <div><img src={EuLogo} alt="Unia Europejska" /></div>
                </div>
                <div className="clicker" onClick={this.handleClick}>
                    {this.props.language === 'en' ? (
                        <img src={EuLogoEn} alt="EU" className="img-fluid" />
                    ) : (
                        <img src={EuLogo} alt="UE" className="img-fluid" />
                    )}
                </div>
            </div>
        );
    }
}

export default EuBanner;