import React, { Component } from 'react';
import TitleBox from "../components/title-box/TitleBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";

import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../components/counter-item/CounterItem";

import BannerImg from "../images/research-process/gathering-data/banner-img.svg";

import Image from "../images/research-process/gathering-data/img.jpg";

import ResearchProcessSecondaryNav from "../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../components/tracking/Tracking";

class GatheringData extends Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this)
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="GatheringData">
                <ResearchProcessSecondaryNav active="3" />
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Zbieranie danych" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">ETAP 3</h6>
                                <JumbotronTitleBox title="Zbieranie danych" paragraph="Zgromadzimy dla Ciebie dane w czasie rzeczywistym, a następnie połączymy wyniki badań z bodźcami."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych" className="active"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc mt-xl coral">ETAP 3</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h4>Integrujemy narzędzia</h4>
                                    <p className="mb-sm introduction">
                                        Łączymy badania wpływu bodźców wzrokowych na&nbsp;aktywność różnych obszarów
                                        mózgu. Badamy zachowania uświadomione i&nbsp;nieuświadomione.
                                    </p>
                                    <h4>Rejestrujemy emocje</h4>
                                    <p className="mb-sm introduction">
                                        Wywiad i&nbsp;ankieta pozwalają na&nbsp;doprecyzowanie doświadczeń odbiorcy w&nbsp;kontakcie
                                        z&nbsp;projektem, na&nbsp;które składają się wspomnienia, intencje czy nastawienie użytkownika.
                                        Odczyty biometryczne i&nbsp;reakcje fizjologiczne skupiają się na&nbsp;rejestrowaniu emocji, refleksji oraz skojarzeń.
                                    </p>
                                    <h4>Analizujemy podświadomość</h4>
                                    <p className="mb-sm introduction">
                                        Z&nbsp;pomocą testów eyetracking i&nbsp;EEG mózgu łączymy badania
                                        wpływu bodźców wzrokowych na&nbsp;aktywność mózgu. Analizujemy obszary mózgu
                                        odpowiedzialne zarówno za&nbsp;decyzje racjonalne, jak i&nbsp;emocjonalne.
                                    </p>
                                    <h4>Sprawdzamy reakcje</h4>
                                    <p className="mb-sm introduction">
                                        W&nbsp;testach psychofizjologicznych analizujemy reakcje skórne oraz tętno.
                                        Stworzone przez nas środowisko badawcze umożliwia porównanie ważności procesów
                                        związanych z&nbsp;User Experience oraz poszczególnych kreacji w&nbsp;projekcie.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="pt-sm pb-l next-section">
                    <div className="container">
                        <Link to="/proces-badawczy/analiza-i-raport" onClick={this.scrollTop}>
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-2">
                                    <h6 className="uc coral">Przejdź do etapu nr 4</h6>
                                    <TitleBox title="Analiza i raport badawczy" paragraph="Wyniki w&nbsp;przejrzystej formie." />

                            </div>
                            <div className="col-12 col-lg-2 align-self-end">
                                <div className="rounded-arrow"></div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>

            </div>
        );
    }
}

export default GatheringData;