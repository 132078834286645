import React, { Component } from 'react';
import './Video.scss';

class Video extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentUrlIdx: 0
        };
        this.handleEnded = this.handleEnded.bind(this)
    }

    handleEnded() {
        const nextUrlIdx = (this.state.currentUrlIdx + 1) % this.props.urls.length
        this.setState({ currentUrlIdx: nextUrlIdx });
    }

    render() {
        return <div className="VideoBg">
            <video src={this.props.url} autoPlay loop muted playsInline/>
        </div>;
    }
}

export default Video;