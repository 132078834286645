import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import Jumbotron from '../../components/jumbotron/Jumbotron.js';
import TitleBox from "../../components/title-box/TitleBox";
import LetsWork from "../../components/lets-work/LetsWork";
import Card from "../../components/card/Card";
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import LogoBox from "../../components/logo-box/LogoBox";
import Video from "../../components/video/Video";
import List from "../../components/list/List";

import {ReactComponent as Amica} from '../../images/logo-amica.svg';
import {ReactComponent as KP} from '../../images/logo-kp.svg';
import {ReactComponent as Nivea} from '../../images/logo-nivea.svg';
import {ReactComponent as Skoda} from '../../images/logo-skoda.svg';
import {ReactComponent as Teva} from '../../images/logo-teva.svg';
import {ReactComponent as VW} from '../../images/logo-vw.svg';

import Video4 from '../../videos/af_lab_odkryj_techniki_2.mp4';
import VideoBg from '../../videos/af-lab_header_dots_02.mp4';

import HeroBg from '../../images/pierwszy_widok_bckgrnd.svg';
import ResearchProcessBg from '../../images/index/bg-2.svg';
import ResearchProcessBg2 from '../../images/index/research-process-bg.svg';

import ResearchProcess1 from '../../images/index/research-design.svg';
import ResearchProcess2 from '../../images/index/method-linking.svg';
import ResearchProcess3 from '../../images/index/gathering-data.svg';
import ResearchProcess4 from '../../images/index/analysis-and-report.svg';

import Cooperation1 from '../../images/index/digital.svg';
import Cooperation2 from '../../images/index/elearning.svg';
import Cooperation3 from '../../images/index/ecommerce.svg';
import Cooperation4 from '../../images/index/btl-atl.svg';

import Respondent from '../../images/index/become-a-respondent.svg';
import Research from '../../images/index/order-research.svg';

import Quotes from '../../images/quotes.svg';

import ScopeBg from '../../images/index/scope-bg.svg';
import {initGA, PageView} from "../../components/tracking/Tracking";

import PlaceholderImage from "../../images/af_lab_miniatura_fb_01.png";
import imageAuthor from "../../images/blog/maciej_raczak.jpg";
import EuBanner from "../../components/eu-banner/EuBanner";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/posts",
            categoryRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/categories",
            //authorsRoute: "http://lab.af.agency/wordpress/wp-json/wp/v2/users/",
            mediaRoute: "https://lab.af.agency/wordpress/wp-json/wp/v2/media",
            posts: [],
            categories: [],
            authors: [],
            images: [],
        }
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
        fetch(this.state.dataRoute)
            .then(res => res.json())
            .then(posts => this.setState((prevState, props) => {
                let tmpPosts = [];
                posts.map(post => {
                    if (tmpPosts.length <=3) {
                        let tmpPost = {
                            title: post.title.rendered,
                            key: post.id,
                            id: post.id,
                            //author: post.author,
                            content: post.content.rendered,
                            excerpt: post.excerpt.rendered,
                            categories: post.categories,
                            images: post.acf.photo,
                        };
                        if (post.acf.language) {
                            if (post.acf.language.toLowerCase() === 'eng') {
                                tmpPosts.push(tmpPost);
                            }
                        }
                    }
                })
                return {
                    posts: tmpPosts
                };
            }));
        fetch(this.state.categoryRoute)
            .then(res => res.json())
            .then(data => {
                this.setState((prevState, props) => {
                    return {categories: data,};
                })
            });
        // fetch(this.state.authorsRoute)
        //     .then(res => res.json())
        //     .then(data => {
        //         this.setState((prevState, props) => {
        //             return {authors: data,};
        //         })
        //     });
        fetch(this.state.mediaRoute)
            .then(res => res.json())
            .then(data => {
                this.setState((prevState, props) => {
                    return {images: data,};
                })
            });
    }

    mapSection(post) {
        if (post.acf.language && post.acf.language.toLowerCase() === 'eng' ) {
            return {
                title: post.title.rendered,
                key: post.id,
                id: post.id,
                //author: post.author,
                content: post.content.rendered,
                excerpt: post.excerpt.rendered,
                categories: post.categories,
                images: post.acf.photo,
            };
        }
    }

    render() {
        const headerStyle = {
            backgroundImage: 'url(' + HeroBg + ')',
            backgroundPosition: 'center center',
            backgroundSize: '100% auto',
            backgroundRepeat: 'no-repeat'
        };

        let isPostsPopulated = false;
        let isCategoryPopulated = false;
        let isAuthorPopulated = false;
        let isMediaPopulated = false;

        if(this.state.posts.length > 0) {
            isPostsPopulated = true;
        }
        if(this.state.categories.length > 0) {
            isCategoryPopulated = true;
        }
        // if(this.state.authors.length > 0) {
        //     isAuthorPopulated = true;
        // }
        if(this.state.images.length > 0) {
            isMediaPopulated = true;
        }

        return (
            <div className="Index">
                <header className="position-relative">
                    <Video url={VideoBg} />
                    <Jumbotron col="6">
                                <TitleBox title="Consumer motivation research" paragraph="Synergy of neuromarketing, psychophysiology and biometry for your development. Our research system is a global innovation - use its potential!" button="Order research" route="/en/order-research/" />
                    </Jumbotron>
                    {/*<div className="dots">*/}
                        {/*<div className="dot"></div>*/}
                        {/*<div className="dot"></div>*/}
                        {/*<div className="dot"></div>*/}
                        {/*<div className="dot"></div>*/}

                        {/*<!-- filters -->*/}
                        {/*<svg xmlns="http://www.w3.org/2000/svg" version="1.1">*/}
                            {/*<defs>*/}
                                {/*<filter id="shadowed-goo">*/}

                                    {/*<feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />*/}
                                    {/*<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />*/}
                                    {/*<feGaussianBlur in="goo" stdDeviation="3" result="shadow" />*/}
                                    {/*<feColorMatrix in="shadow" mode="matrix" values="0 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 -0.2" result="shadow" />*/}
                                    {/*<feOffset in="shadow" dx="1" dy="1" result="shadow" />*/}
                                    {/*<feBlend in2="shadow" in="goo" result="goo" />*/}
                                    {/*<feBlend in2="goo" in="SourceGraphic" result="mix" />*/}
                                {/*</filter>*/}
                                {/*<filter id="goo">*/}
                                    {/*<feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="10" />*/}
                                    {/*<feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -7" result="goo" />*/}
                                    {/*<feBlend in2="goo" in="SourceGraphic" result="mix" />*/}
                                {/*</filter>*/}
                            {/*</defs>*/}
                        {/*</svg>*/}
                    {/*</div>*/}
                </header>

                <section className="py-xl" style={{backgroundColor: '#f2f2f2', backgroundImage: 'url(' + ResearchProcessBg + ')', backgroundSize: 'auto 60%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8 text-center">
                                <JumbotronTitleBox title="How will we help you?" paragraph="Thanks to comprehensive research activities you will learn the real needs of your consumers and employees." />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-xl position-relative overflow-hidden" style={{backgroundColor: '#F2F2F2', backgroundImage: 'url(' + ResearchProcessBg2 + ')', backgroundPosition: 'top left', backgroundRepeat: 'no-repeat'}}>
                    {/*<div className="rounded-bottom-bg"></div>*/}
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <section className="masonry">

                                    <Card title="Designing research" paragraph="We will suggest you the right methodologies and effective research scenarios, thanks to which you will gain reliable knowledge." button="More" route="/en/research-process/designing-research" image={ResearchProcess1} />

                                    <Card title="Combining methods" paragraph="We will select equipment, software and research tools suited for your business needs." button="More" route="/en/research-process/combining-methods" image={ResearchProcess2} />

                                    <Card title="Collecting data" paragraph="We will gather real-time data for you and then combine the results of research with stimuli." button="More" route="/en/research-process/collecting-data" image={ResearchProcess3} />

                                    <Card title="Analysis and research report" paragraph="We will visualize the results of the research, export hard data and give you the opportunity for in-depth analytics." button="More" route="/en/research-process/analysis-and-report" image={ResearchProcess4} />

                                </section>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-xl">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-lg-8 col-xl-7 text-center">
                                <JumbotronTitleBox title="The scope and areas of cooperation" paragraph="Knowledge about motivation is valuable in many business areas.<br />Discover our fields of activity." />
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-xl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                <div className="big-link">
                                    <Link to="/en/research-offer/digital-research" onClick={this.handleClick}>
                                        {/*<div className="placeholder mb-xs"></div>*/}
                                        <div className="card-title">
                                            <img src={Cooperation1} alt="digital" />
                                            <h4>digital</h4>
                                        </div>
                                        <p className="mb-xs"><b>Design websites and digital media that will engage and attract the attention of Internet users.</b></p>
                                        {/*<ul>*/}
                                        {/*<li>badanie UX stron www</li>*/}
                                        {/*<li>testy AB</li>*/}
                                        {/*<li>badanie zaangażowania</li>*/}
                                        {/*<li>badanie atrakcyjności layoutu</li>*/}
                                        {/*</ul>*/}
                                        <List list={['UX survey of websites', 'AB tests', 'engagement study', 'study of layout attractiveness']} />
                                        <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                <div className="big-link">
                                    <Link to="/en/research-offer/elearning-research" onClick={this.handleClick}>
                                        {/*<div className="placeholder mb-xs"></div>*/}
                                        <div className="card-title">
                                            <img src={Cooperation2} alt="e-learning" />
                                            <h4>e‑learning</h4>
                                        </div>
                                        <p className="mb-xs"><b>Create readily available, interesting and effective e-courses for your employees.</b></p>
                                        {/*<ul>*/}
                                        {/*<li>badanie UX materiałów e‑learningowych</li>*/}
                                        {/*<li>analiza efektywności materiałów szkoleniowych</li>*/}
                                        {/*</ul>*/}
                                        <List list={['UX study of e-learning materials', 'analysis of the effectiveness of training materials']} />
                                        <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                <div className="big-link">
                                    <Link to="/en/research-offer/ecommerce-research" onClick={this.handleClick}>
                                        {/*<div className="placeholder mb-xs"></div>*/}
                                        <div className="card-title">
                                            <img src={Cooperation3} alt="e-commerce" />
                                            <h4>e‑commerce</h4>
                                        </div>
                                        <p className="mb-xs"><b>Make your rich product cards convert well in any store environment.</b></p>
                                        {/*<ul>*/}
                                        {/*<li>optymalizacja konwersji</li>*/}
                                        {/*<li>badanie motywacji zakupowych</li>*/}
                                        {/*<li>analiza ścieżki użytkownika</li>*/}
                                        {/*<li>badanie efektywności kart produktowych</li>*/}
                                        {/*</ul>*/}
                                        <List list={['conversion optimization', 'research on purchase motivation', 'user path analysis', 'research on the effectiveness of rich product cards']} />
                                        <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-12 col-sm-6 col-xl-3 mb-xs">
                                <div className="big-link">
                                    <Link to="/en/research-offer/btl-atl-research/" onClick={this.handleClick}>
                                        {/*<div className="placeholder mb-xs"></div>*/}
                                        <div className="card-title">
                                            <img src={Cooperation4} alt="BTL/ATL" />
                                            <h4>BTL/ATL</h4>
                                        </div>
                                        <p className="mb-xs"><b>Investigate the sentiment with respect to the materials you create.</b></p>
                                        {/*<ul>*/}
                                        {/*<li>badania sentymentu dla marek i&nbsp;produktów</li>*/}
                                        {/*<li>badania sentymentu dla materiałów wideo</li>*/}
                                        {/*<li>badania zaangażowania</li>*/}
                                        {/*</ul>*/}
                                        <List list={['sentiment research for brands and products', 'sentiment research for video materials', 'engagement research']} />
                                        <button className="btn btn-primary btn-violet">More <i className="material-icons">arrow_forward</i></button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        {/*<div className="row justify-content-center mt-sm">*/}
                            {/*<div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">*/}
                                {/*<button className="btn btn-outline-dark w-100">Idź do strony <i className="material-icons">arrow_forward</i></button>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    </div>
                </section>

                <section className="position-relative video-bg overflow-hidden" id="video-section-1">
                    <Video url={Video4} />
                    <Jumbotron>
                        <JumbotronTitleBox title="Discover our cutting-edge research techniques" button="More" route="/en/research-offer">
                            <div className="row justify-content-between mt-m">
                                <div className="col-6 col-lg">
                                    <h5>Eye tracking</h5>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <h5>SC and&nbsp;GSR</h5>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <h5>EEG and QEEG</h5>
                                </div>
                                <div className="col-6 col-lg-3">
                                    <h5>Observations and surveys</h5>
                                </div>
                            </div>
                        </JumbotronTitleBox>
                    </Jumbotron>
                </section>

                {
                    isPostsPopulated && isCategoryPopulated && isMediaPopulated && (
                        <section className="blog-excerpt pt-xl">
                            <div className="container">
                                <div className="row justify-content-center">
                                    {this.state.posts.map((post, i) =>
                                        <div key={i} className="single-blog-post col-md-12 col-lg-6 col-xl-4">
                                            <Link to={"/en/blog/post/" + post.id}>
                                                <div className="post-card d-flex flex-column">
                                                    <img src={post.images ? post.images : PlaceholderImage} className="card-img-top post-img" alt="blogpost1"/>
                                                    <h4 className="category mt-4">
                                                        {
                                                            post.categories.map(id => this.state.categories.find(o => o.id === id).name + ' ')

                                                        }
                                                    </h4>
                                                    <h5 className="card-title mt-2" dangerouslySetInnerHTML={{__html: post.title}}></h5>
                                                    <div className="card-title mt-2"
                                                         dangerouslySetInnerHTML={{__html: post.excerpt}}></div>
                                                    <div className="container author mt-auto">
                                                        <div className="row align-items-center">
                                                            <img src={imageAuthor} alt="" className="author-img img-fluid mr-3 ml-3"/>
                                                            <div>
                                                                <p className="author-name">
                                                                    {/*{*/}
                                                                        {/*this.state.authors.find(o => o.id === post.author).name*/}
                                                                    {/*}*/}
                                                                    Maciej Raczak
                                                                </p>
                                                                <div className="author-company">R&D Head, AF.Agency
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    )}
                                </div>
                                {this.state.posts.length < 1 &&
                                (
                                    <h4 className="text-center pb-l">Jeszcze nie ma więcej wpisów.</h4>
                                )
                                }

                            </div>
                            <div className="container pb-5 pt-5">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="line"></div>
                                    </div>
                                </div>
                            </div>
                        </section>

                    )
                }

                <section className="py-m research-section">
                    <div className="container">
                        <div className="row text-center">
                            <div className="col-12 col-lg-6 mb-s">
                                <Card title="Become a respondent" paragraph="If you want to learn about modern research in practice, please contact us as a respondent. See what you can gain from working with us." button="More" route="/en/become-a-respondent" image={Respondent} />
                            </div>
                            <div className="col-12 col-lg-6 mb-s">
                                <Card title="Order tests" paragraph="Get unique quality knowledge, correlated with analytics. Write to us and we will offer a solution tailored to your needs." button="More" route="/en/order-research" image={Research}/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="pb-xl pt-l testimonials">*/}
                    {/*<div className="dot dot-violet dot-xs"></div>*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-md-2">*/}
                                {/*<img src={Quotes} alt="cudzysłów" className="img-fluid mx-auto d-block mb-m" style={{width: '5.625rem'}} />*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-md-10 col-lg-8">*/}
                                {/*/!*<h1>„</h1>*!/*/}
                                {/*<p className="introduction mb-xs">*/}
                                    {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at nulla scelerisque,*/}
                                    {/*fringilla felis vel, convallis ligula. Donec odio ligula, venenatis eu dolor eu,*/}
                                    {/*maximus hendrerit ligula. Curabitur vehicula scelerisque malesuada. Maecenas varius*/}
                                    {/*tristique lacus, et rhoncus turpis dapibus eu.*/}
                                {/*</p>*/}
                                {/*<h4>Jan Kowalski</h4>*/}
                                {/*<h5>Dyrektor operacyjny Nazwa firmy</h5>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <section className="py-xl position-relative" style={{backgroundColor: '#FAFAFA'}}>
                    {/*<div className="dot dot-coral dot-s" style={{left: '20%', top: '-1rem'}}></div>*/}
                    <div className="container pb-m position-relative">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <TitleBox title="They trusted us" paragraph="These are just some of the Partners who have undertaken research cooperation with us."/>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Amica />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <VW />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Skoda />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Teva />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <KP />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Nivea />
                                </LogoBox>
                            </div>
                        </div>
                    </div>
                </section>
                <EuBanner language='en'/>
            </div>
        );
    }
}

export default Index;
