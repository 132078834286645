import React, { Component } from 'react';
import './AccordionHead.scss';

class AccordionHead extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="AccordionHead">
                {this.props.children}
            </div>
        );
    }
}

export default AccordionHead;