import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './ResearchProcessSecondaryNav.scss'; // Tell Webpack that Button.js uses these styles


class Navigation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            prevScrollpos: window.pageYOffset,
            visible: true
        };
        this.scrollTop = this.scrollTop.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    scrollTop() {
        window.scrollTo(0, 0);
        this.handleScroll();
    }

    handleScroll(ev) {
        const { prevScrollpos } = this.state;

        const currentScrollPos = window.pageYOffset;
        const visible = prevScrollpos > currentScrollPos;

        this.setState({
            prevScrollpos: currentScrollPos,
            visible
        });
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <nav className={"py-xs secondary-nav fixed-top " + (!this.state.visible ? "navbar-hidden" : " ")}>
                <div className="container">
                    <div className="row">
                        { this.props.language === 'en' ? (
                            <div className="col-12 text-center d-flex align-items-center justify-content-between">
                                <Link to="/en/research-process/designing-research" onClick={this.scrollTop} className={this.props.active === "1" ? "active" : ""}><div className="bullet"></div><b>Designing research</b></Link>

                                <Link to="/en/research-process/combining-methods" onClick={this.scrollTop} className={this.props.active === "2" ? "active" : ""}><div className="bullet"></div><b>Combining methods</b></Link>

                                <Link to="/en/research-process/collecting-data" onClick={this.scrollTop} className={this.props.active === "3" ? "active" : ""}><div className="bullet"></div><b>Collecting data</b></Link>

                                <Link to="/en/research-process/analysis-and-report" onClick={this.scrollTop} className={this.props.active === "4" ? "active" : ""}><div className="bullet"></div><b>Analysis and research report</b></Link>
                            </div>
                        ) : (
                            <div className="col-12 text-center d-flex align-items-center justify-content-between">
                                <Link to="/proces-badawczy/projektowanie-badan" onClick={this.scrollTop} className={this.props.active === "1" ? "active" : ""}><div className="bullet"></div><b>Projektowanie badań</b></Link>

                                <Link to="/proces-badawczy/technika-laczenia-metod-badawczych" onClick={this.scrollTop} className={this.props.active === "2" ? "active" : ""}><div className="bullet"></div><b>Łączenie metod</b></Link>

                                <Link to="/proces-badawczy/zbieranie-danych" onClick={this.scrollTop} className={this.props.active === "3" ? "active" : ""}><div className="bullet"></div><b>Zbieranie danych</b></Link>

                                <Link to="/proces-badawczy/analiza-i-raport" onClick={this.scrollTop} className={this.props.active === "4" ? "active" : ""}><div className="bullet"></div><b>Analiza i&nbsp;raport badawczy</b></Link>
                            </div>
                        )}
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navigation;