import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './Menu.scss';
import { Transition } from 'react-transition-group';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.fToggle();
    }

    render() {
        const duration = 300;

        const defaultStyle = {
            transition: `opacity ${duration}ms ease-in-out`,
            opacity: 0,
        }

        const transitionStyles = {
            entering: { opacity: 1, display: 'flex' },
            entered:  { opacity: 1, display: 'flex' },
            exiting:  { opacity: 0, display: 'none' },
            exited:  { opacity: 0, display: 'none' },
        };
        return (
                <Transition in={this.props.open}>
                    {state => (
                        <menu className={"Menu d-flex align-items-center justify-content-center " + (this.props.open ? "open" : "")} style={{...defaultStyle, ...transitionStyles[state]}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-12 mb-sm">
                                        {this.props.language === 'en' ? (
                                            <ul>
                                            <Link to="/en/lab/" onClick={this.handleClick}><li><h3 className="bigger white">Lab</h3></li></Link>
                                            <Link to="/en/research-offer/" onClick={this.handleClick}><li><h3 className="bigger white">Research</h3></li></Link>
                                            <Link to="/en/research-process/" onClick={this.handleClick}><li><h3 className="bigger white">Research process</h3></li></Link>
                                            <Link to="/en/blog/" onClick={this.handleClick}><li><h3 className="bigger white">Blog</h3></li></Link>
                                            <Link to="/en/contact/" onClick={this.handleClick}><li><h3 className="bigger white">Contact</h3></li></Link>
                                        </ul>
                                        ) : (
                                            <ul>
                                            <Link to="/laboratorium/" onClick={this.handleClick}><li><h3 className="bigger white">Laboratorium</h3></li></Link>
                                            <Link to="/oferta-badan/" onClick={this.handleClick}><li><h3 className="bigger white">Badania UX/UI</h3></li></Link>
                                            <Link to="/proces-badawczy/" onClick={this.handleClick}><li><h3 className="bigger white">Proces badawczy</h3></li></Link>
                                            <Link to="/blog/" onClick={this.handleClick}><li><h3 className="bigger white">Blog</h3></li></Link>
                                            <Link to="/kontakt/" onClick={this.handleClick}><li><h3 className="bigger white">Kontakt</h3></li></Link>
                                        </ul>
                                        )}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        {this.props.language === 'en' ? (
                                            <Link to="/" onClick={() => {this.props.changeLanguage(); this.handleClick()}} data-language="pl"><i className="material-icons">language</i>Polski</Link>
                                        ) : (
                                            <Link to="/en/" onClick={() => {this.props.changeLanguage(); this.handleClick()}} data-language="en"><i className="material-icons">language</i>English</Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="fixed-bottom pb-xs">*/}
                                {/*<div className="container-fluid">*/}
                                    {/*<div className="row align-items-center">*/}
                                        {/*<div className="col-6 text-center">*/}
                                            {/*<Link to="/zamow-badania/" onClick={this.handleClick}><button className="btn btn-outline-light btn-outline-coral w-100">Zamów badania</button></Link>*/}
                                        {/*</div>*/}
                                        {/*<div className="col-6 text-center">*/}
                                            {/*<Link to="/zostan-respondentem/" onClick={this.handleClick}><button className="btn btn-outline-light btn-outline-coral w-100">Zostań respondentem</button></Link>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="fixed-bottom">
                                {this.props.language === 'en' ? (
                                    <React.Fragment>
                                    <Link to="/en/order-research/" onClick={this.handleClick}><button className="btn btn-dark">Order tests</button></Link>

                                <Link to="/en/become-a-respondent" onClick={this.handleClick}><button className="btn btn-dark">Become a respondent</button></Link>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                    <Link to="/zamow-badania/" onClick={this.handleClick}><button className="btn btn-dark">Zamów badania</button></Link>

                                <Link to="/zostan-respondentem/" onClick={this.handleClick}><button className="btn btn-dark">Zostań respondentem</button></Link>
                                    </React.Fragment>
                                )}

                            </div>
                        </menu>
                    )}
                </Transition>
        );
    }
}

export default Menu;
