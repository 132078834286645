import React, {Component} from 'react';
import TitleBox from "../components/title-box/TitleBox";
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import LogoBox from "../components/logo-box/LogoBox";
import LetsWork from "../components/lets-work/LetsWork";
import Jumbotron from "../components/jumbotron/Jumbotron";
import CounterItem from "../components/counter-item/CounterItem";

import {ReactComponent as Amica} from '../images/logo-amica.svg';
import {ReactComponent as KP} from '../images/logo-kp.svg';
import {ReactComponent as Nivea} from '../images/logo-nivea.svg';
import {ReactComponent as Skoda} from '../images/logo-skoda.svg';
import {ReactComponent as Teva} from '../images/logo-teva.svg';
import {ReactComponent as VW} from '../images/logo-vw.svg';

import Advantages1 from '../images/laboratory/partnership.svg';
import Advantages2 from '../images/laboratory/professionalism.svg';
import Advantages3 from '../images/laboratory/clarity.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class Laboratory extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS stylesW
        return (
            <div className="Laboratory">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>AF.lab - laboratorium wiedzy o motywacjach - badania neuromarketingowe</title>
                    <meta property="og:title" content="AF.lab - laboratorium wiedzy o motywacjach - badania neuromarketingowe" />
                    <meta name="description" content="Usługi badawcze wypracowane wspólnie z autorytetami naukowymi sfery neuromarketingu"/>
                    <meta property="og:description" content="Usługi badawcze wypracowane wspólnie z autorytetami naukowymi sfery neuromarketingu" />
                </Helmet>
                <Jumbotron>
                    <TitleBox isH1={true} title="Źródło wiedzy o&nbsp;motywacji"
                                       paragraph="Współpraca z nami to szansa zdobycia unikalnej wiedzy o motywacji Twoich klientów. Skuteczniej odpowiadając na ich potrzeby, zwiększysz konwersję. Ponadto zapewnisz użytkownikom lepsze doświadczenia: zakupowe, korzystania z produktu oraz pozyskiwania wiedzy.<br /><br />Wykorzystujemy autorski system i proces badawczy wypracowany wspólnie z autorytetami naukowymi sfery neuromarketingu."/>
                </Jumbotron>

                <section className="advantages mt-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="dot dot-xs dot-yellow"></div>
                                <div className="placeholder"
                                     style={{backgroundImage: 'url(' + Advantages1 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Partnerstwo</h3>
                                    <p className="introduction">Plan działania omówimy wspólnie, wykorzystując naszą
                                        wiedzę i&nbsp;narzędzia oraz biorąc pod uwagę Twoje oczekiwania. Następnie
                                        dogłębnie przeanalizujemy i&nbsp;zrelacjonujemy stan rzeczy. Znajdziemy
                                        i&nbsp;rzetelnie opiszemy prawidłowości, które pomogą Ci w&nbsp;rozwoju.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="dot dot-xs dot-violet"></div>
                                <div className="placeholder"
                                     style={{backgroundImage: 'url(' + Advantages2 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="dot dot-s dot-black"></div>
                                <div className="center-box">
                                    <h3>Profesjonalizm</h3>
                                    <p className="introduction">Posiadamy bogate doświadczenie oraz profesjonalny sprzęt
                                        i&nbsp;oprogramowanie. Umiemy nie&nbsp;tylko wykorzystać, ale&nbsp;również
                                        połączyć różnorodne metody badawcze. Dzięki temu możemy zaoferować Ci unikatową
                                        wiedzę jakościową, skorelowaną z&nbsp;analityką.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder"
                                     style={{backgroundImage: 'url(' + Advantages3 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="dot dot-xs dot-coral"></div>
                                <div className="center-box">
                                    <h3>Przejrzystość</h3>
                                    <p className="introduction">Mimo że zajmujemy się bardzo złożoną tematyką,
                                        analizujemy, wnioskujemy i&nbsp;raportujemy dane tak, by&nbsp;zaprezentować
                                        wyniki w&nbsp;sposób przyjazny. Dołączamy również własne rekomendacje działań.
                                        Kompleksowość usługi to&nbsp;także jedna z&nbsp;jej wyjątkowych cech.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-m">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                                <h2 className="bigger">Nasza praca w&nbsp;liczbach</h2>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="counter pt-m">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="10&nbsp;000" paragraph="kart produktowych"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="24" paragraph="kategorie produktowe"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="16" paragraph="platform e‑commerce"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="80+" paragraph="e‑learningów"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="100+" paragraph="aplikacji"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-xl position-relative" style={{backgroundColor: '#FAFAFA'}}>
                    <div className="container pb-m">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <TitleBox title="Zaufali nam"
                                          paragraph="To tylko niektórzy z Partnerów, którzy podjęli z nami współpracę badawczą."/>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Amica/>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <VW/>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Skoda/>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Teva/>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <KP/>
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Nivea/>
                                </LogoBox>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-l">
                        <div className="row">
                            <div className="col-12">
                                <LetsWork/>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default Laboratory;
