import React, { Component } from 'react';
import ContactForm from "../components/contact-form/ContactForm";
import Accordion from "../components/accordion/Accordion";
import Contact from "./Contact";
import {initGA, PageView} from "../components/tracking/Tracking";

class BecomeARespondent extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="BecomeARespondent">
                <article>
                    <section>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6">
                                    <ContactForm formName="br_" />
                                </div>
                                <div className="col-12 col-lg-5 align-self-center">
                                    <Accordion className="coral">
                                        <div label="Realny wpływ na rynek usług">
                                            <p>
                                                Wyniki badań z&nbsp;Twoim udziałem będą kształtowały przyszłość rynku usług
                                                oraz produktów w&nbsp;Polsce i&nbsp;na&nbsp;świecie. Współpracujemy
                                                z&nbsp;dużymi markami, które z&nbsp;pewnością pojawiają się w&nbsp;Twojej
                                                codzienności. Teraz możesz sprawić, aby ich oferta jeszcze bardziej odpowiadała temu, czego naprawdę oczekujesz.
                                            </p>
                                        </div>
                                        <div label="Unikatowe doświadczenie">
                                            <p>
                                                Weź udział w&nbsp;naszych badaniach, a&nbsp;poznasz również lepiej siebie:
                                                okazuje się, że&nbsp;często to, co&nbsp;bierzemy za&nbsp;pewnik, mija się
                                                z&nbsp;prawdą. Odkryjesz, jak tak naprawdę reaguje Twój mózg i&nbsp;jakie
                                                emocje towarzyszą przeglądaniu przez Ciebie treści, którymi jesteś otoczony na co&nbsp;dzień.
                                            </p>
                                        </div>
                                        <div label="Możliwość zdobycia nagród">
                                            <p>
                                                W&nbsp;podziękowaniu za&nbsp;poświęcony nam czas zaoferujemy najbardziej
                                                aktywnym respondentom atrakcyjne nagrody. Ich wartość będzie się różniła
                                                w&nbsp;zależności od&nbsp;czasochłonności danego badania.
                                            </p>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <Contact />
            </div>
        );
    }
}

export default BecomeARespondent;