import React, { Component } from 'react';
import TitleBox from "../../components/title-box/TitleBox";
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import Jumbotron from "../../components/jumbotron/Jumbotron";
import LetsWork from "../../components/lets-work/LetsWork";
import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../../components/counter-item/CounterItem";

import BannerImg from "../../images/research-process/research-design/banner-img.svg";

import Image from "../../images/Image.jpg";

import ResearchProcessSecondaryNav from "../../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../../components/tracking/Tracking";

class ResearchDesign extends Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this)
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="ResearchDesign">
                <ResearchProcessSecondaryNav active="1" language="en" />
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Designing research" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">STEP 1</h6>
                                <JumbotronTitleBox title="Designing research" paragraph="We will offer you relevant methodologies and personalized research scenarios."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan" className="active"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc mt-xl coral">ETAP 1</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">

                                    <h4>We create a research scenario</h4>
                                    <p className="mb-sm introduction">
                                        At the research design stage, we will take into account the individual
                                        needs of your business - so that the knowledge you get as a result of the
                                        analysis will result in the growth of KPIs. Years of experience and partnership
                                        with brands from various industries allow us to act quickly and efficiently.
                                    </p>
                                    <h4>We look to the future</h4>
                                    <p className="mb-sm introduction">
                                        Standard analytics is not enough at the moment, so we decided to combine it
                                        with consumer motivation research. Thanks to this, we can accurately predict
                                        the future of a given industry and effectively help in achieving assumed, long-term goals.
                                    </p>
                                    <h4>We organize the research environment</h4>
                                    <p className="mb-sm introduction">
                                        We carefully select a group of respondents to reflect your current consumers
                                        as accurately as possible. We also have the opportunity to create a research
                                        environment that will analyze the motivation of the target group that you want to acquire.
                                    </p>
                                    <h4>We are looking for the truth, examining emotions</h4>
                                    <p className="mb-sm introduction">
                                        We know that people are most important in valuable research. We will use tools,
                                        methodologies and  modern equipment that allows us to examine the emotions
                                        and truth hidden behind opinions.
                                    </p>
                                    <h4>We build the image of an innovation leader</h4>
                                    <p className="mb-sm introduction">
                                        In the research design stage, we offer the chance of undertaking long term
                                        cooperation with our research laboratory. Not only will you receive accurate
                                        recommendations on a regular basis, but you will also gain the image of
                                        a leading innovator in the industry.
                                    </p>
                                    <h4>Quick valuable knowledge</h4>
                                    <p className="mb-sm introduction">
                                        Together, we will set a satisfactory work schedule. Faster and fuller access
                                        to quality knowledge will allow you to get ahead of your biggest market competitors.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="pt-sm pb-l next-section">
                    <div className="container">
                        <Link to="/en/research-process/combining-methods" className="d-block" onClick={this.scrollTop}>
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-2">

                                    <h6 className="uc coral">Go to step 2</h6>
                                    <TitleBox title="Combining methods" paragraph="Selection of equipment, software and tools." />

                            </div>
                            <div className="col-12 col-lg-2 align-self-end">
                                <div className="rounded-arrow"></div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>
            </div>
        );
    }
}

export default ResearchDesign;