import React, { Component } from 'react';
import ContactForm from "../../components/contact-form/ContactForm";
import Accordion from "../../components/accordion/Accordion";
import Contact from "./Contact";
import {initGA, PageView} from "../../components/tracking/Tracking";

class OrderResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="OrderResearch">
                {/*<section className="py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8">*/}
                                {/*<JumbotronTitleBox title="Zamów badania" paragraph="Uzyskaj unikatową wiedzę jakościową, skorelowaną z analityką. Napisz do nas, a zaproponujemy rozwiązanie dopasowane do Twoich potrzeb."/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article>
                    <section>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6">
                                    <ContactForm formType="1" formName="or_" formLanguage="en" />
                                </div>
                                <div className="col-12 col-lg-5 align-self-center">
                                    {/*<h4>Zwiększysz konwersję i osiągniesz mistrzowskie KPI</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Pomożemy Ci w imponującym zwiększeniu efektywności Twoich działań, niezależnie od branży, w jakiej koncentrujesz swoje działania. Nasza oferta jest skierowana do biznesów działających w e-commerce, BTL/ATL, digital i e-learningu.*/}
                                    {/*</p>*/}
                                    {/*<h4>Zyskasz przewagę nad swoją konkurencją</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Znajdziemy dla Ciebie ścieżkę rozwoju i rozwiązania, które pomogą Ci w wyprzedzeniu konkurencji. Tym samym będziesz mógł zaproponować swoim klientom celne i nowatorskie rozwiązania, których nie znajdą nigdzie indziej.*/}
                                    {/*</p>*/}
                                    {/*<h4>Dowiesz się, czego oczekują Twoi konsumenci</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Twoje przedsiębiorstwo zyska wizerunek user friendly, czyli będzie postrzegane jako takie, które bierze pod uwagę potrzeby i oczekiwania użytkowników. Wierzymy, że świadomy biznes to nie tylko tabele i wyniki, ale również wychodzenie naprzeciw pragnieniom.*/}
                                    {/*</p>*/}
                                    {/*<h4>Zdobędziesz imponujący PR w swojej branży</h4>*/}
                                    {/*<p className="mb-sm introduction">*/}
                                        {/*Współpracę z nami możesz wykorzystać jako element robiącej wrażenie strategii PR-owej. Bądź innowacyjny w swojej branży dzięki nowatorskim rozwiązaniom, które pomogą Ci w osiągnięciu prawdziwie eksperckiego wizerunku.*/}
                                    {/*</p>*/}
                                    <Accordion>
                                            <div label="Increase the effectiveness of your activities">
                                                <p>
                                                    We will help you increase conversions and implement KPIs.
                                                    The offer is addressed to people active in the following areas
                                                    of business: e-commerce, BTL / ATL, digital and e-learning.
                                                </p>
                                            </div>
                                        <div label="Learn the expectations of consumers">
                                            <p>
                                                Thanks to the unique quality knowledge, you will not only offer your
                                                clients and employees accurate and innovative solutions, but also
                                                gain knowledge on how to implement them to the organization.
                                            </p>
                                        </div>
                                        <div label="Use cooperation and innovation in the PR strategy">
                                            <p>
                                                You can use the cooperation with us as part of an impressive PR
                                                strategy. Be innovative in your industry thanks to solutions that
                                                will help you achieve a truly expert image.
                                            </p>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                {/*<section className="ContactForm py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row justify-content-center">*/}
                            {/*<div className="col-12 col-lg-10 col-xl-8">*/}
                                {/*<ContactForm />*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <Contact />
            </div>
        );
    }
}

export default OrderResearch;