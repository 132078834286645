import React, { Component } from 'react';
import TitleBox from "../../components/title-box/TitleBox";
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import LogoBox from "../../components/logo-box/LogoBox";
import LetsWork from "../../components/lets-work/LetsWork";
import Jumbotron from "../../components/jumbotron/Jumbotron";
import CounterItem from "../../components/counter-item/CounterItem";

import {ReactComponent as Amica} from '../../images/logo-amica.svg';
import {ReactComponent as KP} from '../../images/logo-kp.svg';
import {ReactComponent as Nivea} from '../../images/logo-nivea.svg';
import {ReactComponent as Skoda} from '../../images/logo-skoda.svg';
import {ReactComponent as Teva} from '../../images/logo-teva.svg';
import {ReactComponent as VW} from '../../images/logo-vw.svg';

import Advantages1 from '../../images/laboratory/partnership.svg';
import Advantages2 from '../../images/laboratory/professionalism.svg';
import Advantages3 from '../../images/laboratory/clarity.svg';
import {initGA, PageView} from "../../components/tracking/Tracking";

class Laboratory extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="Laboratory">
                <Jumbotron>
                                <JumbotronTitleBox title="A source of knowledge about motivation" paragraph="Cooperation with us is a chance to gain unique knowledge about the motivation of your clients. By responding more effectively to their needs, you will increase your conversion. In addition, you will provide users with better experience: purchasing, using the product and acquiring knowledge. We use the proprietary system and research process developed jointly with the scientific authorities of the neuromarketing sphere." />
                </Jumbotron>

                <section className="advantages mt-xs">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="dot dot-xs dot-yellow"></div>
                                <div className="placeholder" style={{backgroundImage: 'url(' + Advantages1 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="center-box">
                                    <h3>Partnership</h3>
                                    <p className="introduction">We will discuss the action plan together, using our knowledge and tools and taking your expectations into account. Then we will thoroughly analyze and report the state of affairs. We will find and reliably describe the regularities that will help you in development.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="dot dot-xs dot-violet"></div>
                                <div className="placeholder" style={{backgroundImage: 'url(' + Advantages2 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="dot dot-s dot-black"></div>
                                <div className="center-box">
                                    <h3>Professionalism</h3>
                                    <p className="introduction">We have extensive experience and professional equipment and software. We can not only use, but also combine various research methods. Thanks to this, we can offer you unique quality knowledge, correlated with the analytics.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 col-lg-6">
                                <div className="placeholder" style={{backgroundImage: 'url(' + Advantages3 + ')'}}></div>
                            </div>
                            <div className="col-12 col-lg-6 d-flex">
                                <div className="dot dot-xs dot-coral"></div>
                                <div className="center-box">
                                    <h3>Clarity</h3>
                                    <p className="introduction">Although we deal with a very complex subject matter, we analyze, conclude and report data to present results in a friendly way. We also include our own recommendations for actions. The complexity of the service is also one of its unique features.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="pb-m">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-10 offset-lg-1 col-xl-8 offset-xl-2">
                                <h1>Our work in numbers</h1>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="counter pt-m">
                    <div className="container">
                        <div className="row justify-content-end">
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="10,000" paragraph="rich product cards"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="24" paragraph="product categories"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="16" paragraph="e‑commerce platforms"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="80+" paragraph="e‑learning courses"/>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4 mb-m">
                                <CounterItem title="100+" paragraph="applications"/>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="py-xl position-relative" style={{backgroundColor: '#FAFAFA'}}>
                    <div className="container pb-m position-relative">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <TitleBox title="They trusted us" paragraph="These are just some of the Partners who have undertaken research cooperation with us."/>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Amica />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <VW />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Skoda />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Teva />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <KP />
                                </LogoBox>
                            </div>
                            <div className="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2">
                                <LogoBox>
                                    <Nivea />
                                </LogoBox>
                            </div>
                        </div>
                    </div>
                    <div className="container mt-l">
                        <div className="row">
                            <div className="col-12">
                                <LetsWork language="en" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default Laboratory;