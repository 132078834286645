import React, { Component } from 'react';
import axios from 'axios';
import './ContactForm.scss';

const API_PATH = 'https://lab.af.agency/api/contact/index.php';

class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            email: '',
            message: '',
            checkbox: false,
            mailSent: false,
            error: null,
            formType: this.props.formType,
            registerFormSent: null,
            registerFormError: null,
            formErrors: {
                phone: false,
                email: false,
                message: false,
                checkbox: false
            }
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleMessage = this.handleMessage.bind(this);
        this.insertData = this.insertData.bind(this);
        this.handleRegisterForm = this.handleRegisterForm.bind(this);
    }

    handleFormSubmit = e => {
        e.preventDefault();
        if ((this.state.email || this.state.phone) && this.state.message && this.state.checkbox) {
            axios({
                method: 'post',
                url: `${API_PATH}`,
                headers: {'content-type': 'application/json'},
                data: this.state
            })
                .then(result => {
                    this.setState({
                        mailSent: result.data.sent
                    })
                })
                .catch(error => {
                    this.setState({
                        error: error.message
                    })
                })
        } else {
            if (!this.state.message) {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.message = true;
                    return {formErrors};
                })
            } else {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.message = false;
                    return {formErrors};
                })
            }
            if (!this.state.checkbox) {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.checkbox = true;
                    return {formErrors};
                })
            } else {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.checkbox = false;
                    return {formErrors};
                })
            }
            if (!(this.state.phone || this.state.email)) {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.email = true;
                    formErrors.phone = true;
                    return {formErrors};
                })
            } else {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.email = false;
                    formErrors.phone = false;
                    return {formErrors};
                })
            }
        }
    };

    handleRegisterForm = e => {
        e.preventDefault();
        if (this.state.email && this.state.checkbox) {
            this.getWPnonce();
        } else {
            if (!this.state.email) {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.email = true;
                    return {formErrors};
                })
            } else {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.email = false;
                    return {formErrors};
                })
            }
            if (!this.state.checkbox) {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.checkbox = true;
                    return {formErrors};
                })
            } else {
                this.setState(prevState => {
                    let formErrors = Object.assign({}, prevState.formErrors);
                    formErrors.checkbox = false;
                    return {formErrors};
                })
            }
        }
    };

    handleMessage(e) {
        let el = e.target;
        this.setState({message: el.innerHTML});
        // let range = document.createRange();
        // let sel = window.getSelection();
        // range.setStart(el.childNodes[1], 4);
        // range.collapse(true);
        // sel.removeAllRanges();
        // sel.addRange(range);
        // el.focus();
        // console.log(el.childNodes);
    }

    getWPnonce() {
        axios.get('https://lab.af.agency/wordpress/api/get_nonce/?controller=user&method=register')
            .then(res => {
                this.insertData(res.data.nonce);
            }).catch(error => {
                console.log(error.response);
        });
    }

    formSent = () => {
        if (this.state.mailSent && this.props.formLanguage === 'en') {
            return (
                <div className="alert alert-success" role="alert">
                    Thank you for your message, we will contact you shortly.
                </div>
            );
        } else if (this.state.mailSent) {
            return (
                <div className="alert alert-success" role="alert">
                    Dziękujemy, formularz został wysłany!
                </div>
            );
        } else {
            return false
        }
    }

    registerFormSent = () => {
        if (this.state.registerFormSent && this.props.formLanguage === 'en') {
            return(
                <div className="alert alert-success" role="alert">
                    Thank you, you are now registered.
                </div>
            );
        } else if (this.state.registerFormSent) {
            return (
                <div className="alert alert-success" role="alert">
                    Dziękujemy, użytkownik został zarejestrowany.
                </div>
            );
        } else if (this.state.registerFormSent === false && this.props.formLanguage === 'en') {
            return (
                <div className="alert alert-danger" role="alert">
                    We are sorry, an error occurred. Try again later. Error description below:<br />
                    <b>{this.state.registerFormError}</b>
                </div>
            );
        } else if (this.state.registerFormSent === false) {
            return (
                <div className="alert alert-danger" role="alert">
                    Przepraszamy, wystąpił błąd. Spróbuj ponownie później. Opis błędu poniżej:<br />
                    <b>{this.state.registerFormError}</b>
                </div>
            );
        }
    }

    insertData(nonce) {
        axios.get('https://lab.af.agency/wordpress/api/user/register/?username=' + this.state.email + '&email=' + this.state.email + '&nonce=' + nonce + '&display_name=' + this.state.email + '&insecure=cool&notify=both')
            .then(res => {
                this.setState({
                    registerFormSent: true,
                    checkbox: false,
                    email: ''
                })
            }).catch(error => {
                this.setState({
                    registerFormSent: false,
                    registerFormError: error.response.data.error
                });
                console.log(error.response);
        });
    }

    render() {
        // You can use them as regular CSS styles
        let txt = '';

        switch(this.props.formType) {
            case "1":
                txt = this.props.formLanguage === 'en' ? '<h2>Are you interested in our research?</h2><div class="mb-xs"><p class="introduction mb-xs">Write to us and we will offer a solution tailored to your needs.</p></div>' : '<h2>Zainteresowały Cię nasze badania?</h2><div class="mb-xs"><p class="introduction mb-xs">Napisz do&nbsp;nas, a&nbsp;zaproponujemy rozwiązanie dopasowane do&nbsp;Twoich potrzeb.</p></div>';
                break;
            case "2":
                txt = '';
                break;
            default:
                txt = this.props.formLanguage === 'en' ? '<h2>Are you interested in our research?</h2><div class="mb-xs"><p class="introduction mb-xs">If you want to learn about modern research in practice, please contact us as a respondent.</p></div>' : '<h2>Zainteresowały Cię nasze badania?</h2><div class="mb-xs"><p class="introduction mb-xs">Jeśli chcesz poznać nowoczesne badania w praktyce, zgłoś się do nas w&nbsp;charakterze respondenta.</p></div>';
        }

        return (
            <div className="ContactForm">
                <div dangerouslySetInnerHTML={{__html: txt}}></div>
                {this.props.formType ? (
                    <form action="#">
                        <div className="form-group">
                            <label htmlFor={this.props.formName + "contactFormPhoneNumber"}><b>{this.props.formLanguage === 'en' ? 'Phone number' : 'Numer telefonu'}</b></label>
                            <input type="tel"
                                   className={"form-control " + (this.state.phone ? "" : "empty ") + (this.state.formErrors.phone ? "invalid" : "")}
                                   id={this.props.formName + "contactFormPhoneNumber"}
                                   aria-describedby={this.props.formName + "contactFormPhoneNumberHelp"}
                                   value={this.state.phone}
                                   onChange={e => this.setState({phone: e.target.value})}
                            />
                            <small id={this.props.formName + "contactFormPhoneNumberHelp"} className="form-text text-muted">&nbsp;</small>
                        </div>
                        <div className="form-group">
                            <label htmlFor={this.props.formName + "contactFormEmail"}><b>{this.props.formLanguage === 'en' ? 'E-mail address' : 'Adres e-mail'}</b></label>
                            <input type="email"
                                   className={"form-control " + (this.state.email ? "" : "empty ") + (this.state.formErrors.email ? "invalid" : "")}
                                   id={this.props.formName + "contactFormEmail"}
                                   aria-describedby={this.props.formName + "contactFormEmailHelp"}
                                   value={this.state.email}
                                   onChange={e => this.setState({email: e.target.value})}
                            />
                            <small id={this.props.formName + "contactFormEmailHelp"} className="form-text text-muted">&nbsp;</small>
                        </div>
                        {/*<div className="form-group">*/}
                        {/*<label htmlFor="contactFormMessage">Napisz wiadomość</label>*/}
                        {/*<div*/}
                        {/*className={"form-control " + (this.state.message ? "" : "empty")}*/}
                        {/*id="contactFormMessage"*/}
                        {/*contentEditable*/}
                        {/*onKeyUp={this.handleMessage}*/}
                        {/*>*/}
                        {/*{this.state.message}*/}
                        {/*</div>*/}
                        {/*<small id="contactFormMessageHelp" className="form-text text-muted">&nbsp;</small>*/}
                        {/*</div>*/}
                        <div className="form-group">
                            <label htmlFor={this.props.formName + "contactFormMessage"}><b>{this.props.formLanguage === 'en' ? 'Write a message' : 'Napisz wiadomość'}</b></label>
                            <textarea
                                className={"form-control " + (this.state.message ? "" : "empty ") + (this.state.formErrors.message ? "invalid" : "")}
                                id={this.props.formName + "contactFormMessage"}
                                value={this.state.message}
                                onChange={e => this.setState({message: e.target.value})}
                            />
                            <small id={this.props.formName + "contactFormMessageHelp"} className="form-text text-muted">&nbsp;</small>
                        </div>
                        <div className="form-group form-check mb-xs">
                            <label className="form-check-label custom-checkbox" htmlFor={this.props.formName + "contactFormGdpr"}>
                                <input type="checkbox" className="form-check-input" id={this.props.formName + "contactFormGdpr"} required checked={this.state.checkbox} onChange={e => this.setState({checkbox: !this.state.checkbox})} />
                                <span className={"checkmark " + (this.state.formErrors.checkbox ? "invalid" : "")}></span>
                                {this.props.formLanguage === 'en' ? (
                                    <small>
                                        I agree to the processing of my personal data, in accordance with the Law on the protection of personal data, in connection with sending a request via the contact form. Providing the data is voluntary, but necessary to process the query. I have been informed that I have the right to access my data, the possibility of correcting it, requesting discontinuation of its processing. The personal data administrator is AF.agency with registered office at ul. Saperska 42C / 14, 60-493 Poznań.
                                    </small>
                                ) : (
                                    <small>
                                        Wyrażam zgodę na przetwarzanie moich danych osobowych, zgodnie z ustawą o ochronie danych osobowych, w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem/am poinformowany/a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawienia, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest firma AF.agency z siedzibą: ul. Saperska 42C/14, 60-493 Poznań.
                                    </small>
                                )}
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-4">
                                <button type="submit" className="btn btn-primary mb-xs w-100" onClick={this.handleFormSubmit}>{this.props.formLanguage === 'en' ? 'Send' : 'Wyślij'}</button>
                            </div>
                        </div>
                        <div>
                            {this.formSent()}
                        </div>
                    </form>
                ) : (
                    <form action="#">
                        <div className="form-group">
                            <label htmlFor={this.props.formName + "contactFormEmail"}><b>{this.props.formLanguage === 'en' ? 'E-mail address' : 'Adres e-mail'}</b></label>
                            <input type="email"
                                   className={"form-control " + (this.state.email ? "" : "empty ") + (this.state.formErrors.email ? "invalid" : "")}
                                   id={this.props.formName + "contactFormEmail"}
                                   aria-describedby={this.props.formName + "contactFormEmailHelp"}
                                   value={this.state.email}
                                   onChange={e => this.setState({email: e.target.value})}
                            />
                            <small id={this.props.formName + "contactFormEmailHelp"} className="form-text text-muted">&nbsp;</small>
                        </div>
                        <div className="form-group form-check mb-xs">
                            <label className="form-check-label custom-checkbox" htmlFor={this.props.formName + "contactFormGdpr"}>
                                <input type="checkbox" className="form-check-input" id={this.props.formName + "contactFormGdpr"} required checked={this.state.checkbox} onChange={e => this.setState({checkbox: !this.state.checkbox})} />
                                <span className={"checkmark " + (this.state.formErrors.checkbox ? "invalid" : "")}></span>
                                {this.props.formLanguage === 'en' ? (
                                    <small>
                                        I agree to the processing of my personal data, in accordance with the Law on the protection of personal data, in connection with sending a request via the contact form. Providing the data is voluntary, but necessary to process the query. I have been informed that I have the right to access my data, the possibility of correcting it, requesting discontinuation of its processing. The personal data administrator is AF.agency with registered office at ul. Saperska 42C / 14, 60-493 Poznań.
                                    </small>
                                ) : (
                                    <small>
                                        Wyrażam zgodę na przetwarzanie moich danych osobowych, zgodnie z ustawą o ochronie danych osobowych, w związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale niezbędne do przetworzenia zapytania. Zostałem/am poinformowany/a, że przysługuje mi prawo dostępu do swoich danych, możliwości ich poprawienia, żądania zaprzestania ich przetwarzania. Administratorem danych osobowych jest firma AF.agency z siedzibą: ul. Saperska 42C/14, 60-493 Poznań.
                                    </small>
                                )}
                            </label>
                        </div>
                        <div className="row">
                            <div className="col-6 col-sm-4 col-md-6 col-lg-5 col-xl-4">
                                <button type="submit" className="btn btn-primary mb-xs w-100" onClick={this.handleRegisterForm}>{this.props.formLanguage === 'en' ? 'Sign up' : 'Zarejestruj się'}</button>
                            </div>
                        </div>
                        <div>
                            {this.registerFormSent()}
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

export default ContactForm;
