import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './Footer.scss';
import {ReactComponent as Logo} from '../../images/Logo.svg';
import {ReactComponent as FbLogo} from '../../images/footer/facebook-brands.svg';
import {ReactComponent as InLogo} from '../../images/footer/linkedin-brands.svg';
import PrivacyPolicy from '../../files/2019_06_19_polityka_prywatnosci.pdf';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <footer className="Footer py-xl">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10">
                            <div className="row">
                                <div className="col-12 col-md-5 pb-s">
                                    {this.props.context.state.language === 'en' ? (
                                        <React.Fragment>
                                            <Link to={{ pathname: "https://af.agency/" }} target="_blank" onClick={this.handleClick}><Logo /></Link>
                                            <p className="large mt-xs">A source of knowledge about motivation</p>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            <Link to={{ pathname: "https://af.agency/" }} target="_blank" onClick={this.handleClick}><Logo /></Link>
                                            <p className="large mt-xs">Źródło wiedzy o motywacjach. Badania użyteczności, analiza i audyt UX, CX, UI. Synergia neuromarketingu, psychofizjologii i biometrii dla rozwoju Twojego e&#8209;commerce, e&#8209;learning oraz rozwiązań digitalowych.</p>
                                        </React.Fragment>
                                        )}
                                    <div className="social mt-sm">
                                        <a href="https://www.facebook.com/artflash" target="_blank"><FbLogo/></a> <a href="https://www.linkedin.com/company/af.agency/" target="_blank"><InLogo/></a>
                                    </div>
                                </div>
                                <div className="col-12 col-md-7">
                                    <div className="row">
                                        <div className="col-12 col-lg-4 text-lg-right pb-s">
                                            <h6>Navigation</h6>
                                            {this.props.context.state.language === 'en' ? (
                                                <ul className="nav">
                                                    <Link to="/en/lab/" onClick={this.handleClick}><li>Lab</li></Link>
                                                    <Link to="/en/research-offer/" onClick={this.handleClick}><li>Research</li></Link>
                                                    <Link to="/en/research-process/" onClick={this.handleClick}><li>Research process</li></Link>
                                                    <Link to="/en/blog/" onClick={this.handleClick}><li>Blog</li></Link>
                                                    <Link to="/en/contact/" onClick={this.handleClick}><li>Contact</li></Link>
                                                </ul>
                                            ) : (
                                                <ul className="nav">
                                                    <Link to="/laboratorium/" onClick={this.handleClick}><li>Laboratorium</li></Link>
                                                    <Link to="/oferta-badan/" onClick={this.handleClick}><li>Badania</li></Link>
                                                    <Link to="/proces-badawczy/" onClick={this.handleClick}><li>Proces badawczy</li></Link>
                                                    <Link to="/blog/" onClick={this.handleClick}><li>Blog</li></Link>
                                                    <Link to="/kontakt/" onClick={this.handleClick}><li>Kontakt</li></Link>
                                                </ul>
                                            )}
                                        </div>
                                        <div className="col-12 col-lg-4 text-lg-right pb-s">
                                            <h6>R&D Head</h6>
                                            <p>Rafał Bobak</p>
                                            <p><a href="tel:+48601401399">+48 601 401 399</a></p>
                                            <p><a href="mailto:rafal.bobak@af.agency">rafal.bobak@af.agency</a></p>
                                        </div>
                                        <div className="col-12 col-lg-4 text-lg-right pb-s">
                                            <h6>AF.agency</h6>
                                            <address>
                                                <p>Małe Garbary 9</p>
                                                <p>61-756 Poznań, Poland</p>
                                            </address>
                                            <p><a href="https://af.agency/" target="_blank">AF.agency</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 mt-m privacy-policy">
                            <p>© 2019 AF.agency All rights reserved.</p>
                            {this.props.context.state.language === 'en' ? (
                                <a href={PrivacyPolicy} target="_blank" rel="noopener norefferer">Privacy policy</a>
                            ) : (
                                <a href={PrivacyPolicy} target="_blank" rel="noopener norefferer">Polityka prywatności</a>
                            )}
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
