import React, { Component } from 'react';
import './JobBox.scss';
import { BrowserRouter as Router, Link } from 'react-router-dom';

class JobBox extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="JobBox">
                <h4>{this.props.title}</h4>
                <p className="large">{this.props.hours}</p>
                <Link to={this.props.route} onClick={this.handleClick}><button className="btn btn-outline-dark mt-xs">{this.props.button ? this.props.button : 'Details'} <i className="material-icons">arrow_forward</i></button></Link>
            </div>
        );
    }
}

export default JobBox;