import React, { Component } from 'react';
import './JoinUs.scss'; // Tell Webpack that Button.js uses these styles
import TitleBox from '../title-box/TitleBox';
import { BrowserRouter as Router, Link } from 'react-router-dom';

class LetsWork extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <section className="LetsWork py-l">
                <div className="jumbotron-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-8 col-xl-6 text-center">
                                <TitleBox title="Join us" paragraph="Nobody is perfect. Don’t be afraid to fall. There is no other way to move forward." />
                                <Link to="/contact/"><button className="btn btn-outline-dark mt-sm">Apply</button></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default LetsWork;