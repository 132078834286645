import React, { Component } from 'react';
import './Loader.scss';

class Loader extends Component {
    render() {
        return (
            <div className={"loader " + (this.props.fetchComplete ? "hidden" : "shown")}>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        );
    }
}

export default Loader;