import React, {Component} from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../components/contact-form/ContactForm";

import BannerImg from "../images/research/e-commerce/banner-img.svg"

import {Link, animateScroll as scroll} from 'react-scroll';

import ResearchSecondaryNav from "../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class EcommerceResearch extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="EcommerceResearch">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>Af.lab - badania i optymalizacja konwersji w e-commerce</title>
                    <meta property="og:title" content="Af.lab - badania i optymalizacja konwersji w e-commerce"/>
                    <meta name="description"
                          content="Badanie efektywności kart produktowych, optymalizacja konwersji, badanie motywacji zakupowych, analiza ścieżki użytkownika"/>
                    <meta property="og:description"
                          content="Badanie efektywności kart produktowych, optymalizacja konwersji, badanie motywacji zakupowych, analiza ścieżki użytkownika"/>
                </Helmet>
                <ResearchSecondaryNav active="3"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="e-commerce" className="img-fluid d-block mx-auto"/>
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">e-commerce</h5>
                                <JumbotronTitleBox
                                    title="Badania użyteczności, analiza i&nbsp;audyt UX i&nbsp;CX"
                                    paragraph="Optymalizacja konwersji Twojego e&#8209;commerce. Nasze badania określają emocje odbiorcy oraz jego zaangażowanie w działanie na poszczególnych etapach procesu zakupowego, aby umożliwić Ci usprawnienie i skrócenie drogi użytkownika do finalizacji zakupu."/>
                                <Link to="ContactForm" smooth={true}>
                                    <button className="btn btn-primary btn-violet mt-s">Zamów badania</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Wybierz badania dla Twojego e&#8209;commerce</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badania użyteczności, analiza i audyt UX i CX
                                    </h4>
                                    <p className="large">
                                        W ramach badań użyteczności zidentyfikujemy wszystkie najważniejsze elementy
                                        związane z Twoim e-commerce. Wynikiem analizy będzie audyt, który pomoże Tobie
                                        uzyskać:
                                    </p>
                                    <ul>
                                        <li>wzrost sprzedaży,</li>
                                        <li>wzrost zaangażowania,</li>
                                        <li>zwiększenie liczby konwersji,</li>
                                        <li>poprawa współczynnika odrzuceń,</li>
                                        <li>poprawa satysfakcji Klientów,</li>
                                        <li>analiza ścieżki i zachowań użytkowników,</li>
                                        <li>identyfikacja problemów,</li>
                                        <li>zwiększenie przewagi konkurencyjnej.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie i analiza ścieżki użytkownika
                                    </h4>
                                    <p className="large">
                                        Zgłębiamy big data, wykonujemy testy eyetracking oraz uzupełniamy badania
                                        wynikami pracy z grupą focusową. Rezultaty tych działań zoptymalizują ścieżkę
                                        zakupową oraz wyeliminują elementy, które zaburzają lub blokują konwersję w
                                        sklepie internetowym.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie motywacji zakupowych
                                    </h4>
                                    <p className="large">
                                        Eyetracking, skin conductance oraz badania EEG tworzą zaawansowany zestaw
                                        testów, który pozwala przyjrzeć się bliżej emocjom użytkownika i poznać jego
                                        prawdziwe motywacje zakupowe. Otrzymane wyniki mogą być czynnikami
                                        zwiększającymi konwersję.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">04</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie efektywności kart produktowych
                                    </h4>
                                    <p className="large">
                                        Dogłębnie analizujemy procesy zakupowe poprzez zestawienie wyników badań
                                        eyetrackingowych z testami psychofizjologicznymi oraz badaniem EEG. W oparciu o
                                        wyniki analizy zwiększysz atrakcyjność layoutu kart produktowych z poziomu UX
                                        danej strony.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">05</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Opracowanie indywidualnego procesu badawczego
                                    </h4>
                                    <p className="large">
                                        Potrzebujesz indywidualnie dopasowanego procesu badawczego, by zbadać swoje
                                        działania w obszarze e‑commerce? Nasz zespół zaprojektuje badania precyzyjnie
                                        dostosowane do Twoich potrzeb. Wypełnij formularz zgłoszeniowy, a my
                                        skontaktujemy się z Tobą.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{
                    backgroundImage: 'url(' + ContactFormBg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left 10% bottom 30%'
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Zostaw swoje dane, a&nbsp;przedstawimy Ci plan badań</h2>
                                <p className="introduction">
                                    Napisz do&nbsp;nas i&nbsp;opowiedz o&nbsp;swoich potrzebach, a&nbsp;wrócimy
                                    z&nbsp;propozycją współpracy badawczej, która zaowocuje rozkwitem Twojego biznesu.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2"/>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default EcommerceResearch;
