import React, { Component } from 'react';
import TitleBox from "../../components/title-box/TitleBox";
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";

import { BrowserRouter as Router, Link } from 'react-router-dom';
import CounterItem from "../../components/counter-item/CounterItem";

import BannerImg from "../../images/research-process/gathering-data/banner-img.svg";

import Image from "../../images/research-process/gathering-data/img.jpg";

import ResearchProcessSecondaryNav from "../../components/research-process-secondary-nav/ResearchProcessSecondaryNav";
import {initGA, PageView} from "../../components/tracking/Tracking";

class GatheringData extends Component {
    constructor(props) {
        super(props);
        this.scrollTop = this.scrollTop.bind(this)
    }

    scrollTop() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="GatheringData">
                <ResearchProcessSecondaryNav active="3" language="en" />
                <section className="py-xl">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="Collecting data" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1">
                                <h6 className="uc coral">STEP 3</h6>
                                <JumbotronTitleBox title="Collecting data" paragraph="We will gather real-time data for you and then combine the results of research with stimuli."/>
                            </div>
                        </div>
                    </div>
                </section>

                {/*<section className="counter py-xl">*/}
                    {/*<div className="container">*/}
                        {/*<div className="row">*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="01 Szybkie działanie "/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="02 Spersonalizowany scenariusz"/>*/}
                            {/*</div>*/}
                            {/*<div className="col-12 col-lg-4">*/}
                                {/*<CounterItem title="" paragraph="03 Unikatowa wiedza"/>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</section>*/}

                <article className="mb-xl">
                    {/*<nav className="py-sm secondary-nav">*/}
                        {/*<div className="container">*/}
                            {/*<div className="row">*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/projektowanie-badan"><img src={ResearchProcess1} alt="Projektowanie badań" /><b>Projektowanie badań</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/technika-laczenia-metod-badawczych"><img src={ResearchProcess2} alt="Łączenie metod" /><b>Łączenie metod</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/zbieranie-danych" className="active"><img src={ResearchProcess3} alt="Zbieranie danych" /><b>Zbieranie danych</b></Link>*/}
                                {/*</div>*/}
                                {/*<div className="col-12 col-md-6 col-lg-3">*/}
                                    {/*<Link to="/proces-badawczy/analiza-i-raport"><img src={ResearchProcess4} alt="Analiza i raport badawczy" /><b>Analiza i&nbsp;raport badawczy</b></Link>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                        {/*</div>*/}
                    {/*</nav>*/}

                    <section>
                        <div className="container">
                            {/*<div className="row justify-content-center">*/}
                                {/*<div className="col-12 col-lg-8 col-xl-6 mb-sm">*/}
                                    {/*<h6 className="uc mt-xl coral">ETAP 3</h6>*/}
                                    {/*<h3>Ustalenie celów, metodyk i&nbsp;technik badań, które pomogą Ci w rozwoju</h3>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className="row justify-content-center mb-sm">
                                <div className="col-12 col-lg-10">
                                    <div className="img-fluid placeholder placeholder-horizontal" style={{backgroundImage: 'url(' + Image + ')'}}></div>
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-lg-8 col-xl-6">
                                    <h4>We integrate tools</h4>
                                    <p className="mb-sm introduction">
                                        We combine studies of the influence of visual stimuli on the activity of various
                                        areas of the brain. We study behaviors that are conscious and unconscious.
                                    </p>
                                    <h4>We record emotions</h4>
                                    <p className="mb-sm introduction">
                                        Interview and questionnaire allow to clarify the recipient's experiences
                                        in contact with the project, which consist of memories, intentions or
                                        the attitude of the user. Biometric readings and physiological reactions
                                        are focused on the recording of emotions, reflections and associations.
                                    </p>
                                    <h4>We analyze the subconsciousness</h4>
                                    <p className="mb-sm introduction">
                                        With the help of eyetracking and EEG brain tests, we combine studies of the
                                        influence of visual stimuli on brain activity. We analyze areas of the brain
                                        that are responsible for both rational and emotional decisions.
                                    </p>
                                    <h4>We check the reactions</h4>
                                    <p className="mb-sm introduction">
                                        In psychophysiological tests, we analyze skin reactions and heart rate.
                                        The research environment we created allows us to compare the importance
                                        of processes related to UX and individual creations in the project.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="pt-sm pb-l next-section">
                    <div className="container">
                        <Link to="/en/research-process/analysis-and-report" onClick={this.scrollTop}>
                        <div className="row">
                            <div className="col-12 col-lg-6 offset-lg-2">
                                    <h6 className="uc coral">Go to step 4</h6>
                                    <TitleBox title="Analysis and research report" paragraph="Results in a clear manner" />

                            </div>
                            <div className="col-12 col-lg-2 align-self-end">
                                <div className="rounded-arrow"></div>
                            </div>
                        </div>
                        </Link>
                    </div>
                </section>

            </div>
        );
    }
}

export default GatheringData;