import React, { Component } from 'react';
import ContactForm from "../../components/contact-form/ContactForm";
import Accordion from "../../components/accordion/Accordion";
import Contact from "./Contact";
import {initGA, PageView} from "../../components/tracking/Tracking";

class BecomeARespondent extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="BecomeARespondent">
                <article>
                    <section>
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-12 col-lg-6">
                                    <ContactForm formName="br_" formLanguage="en" />
                                </div>
                                <div className="col-12 col-lg-5 align-self-center">
                                    <Accordion className="coral">
                                        <div label="Real impact on the services market">
                                            <p>
                                                The research results with your participation will shape the future
                                                of the services and products market in Poland and in the world.
                                                We work with large brands that are sure to appear in your everyday life.
                                                Now you can make their offer even more suited to what you really expect.
                                            </p>
                                        </div>
                                        <div label="Unique experience">
                                            <p>
                                                Take part in our research, and you will also get to know yourself
                                                better: it turns out that often what we take for granted is not true.
                                                You will discover how your brain really reacts and what emotions
                                                accompany the browsing of the content you are surrounded by on a daily basis.
                                            </p>
                                        </div>
                                        <div label="The opportunity to win prizes">
                                            <p>
                                                As a thank you for your time, we will offer attractive rewards to the
                                                most active respondents. Their value will vary depending on the duration of the given test.
                                            </p>
                                        </div>
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <Contact />
            </div>
        );
    }
}

export default BecomeARespondent;