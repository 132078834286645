import React, { Component } from 'react';
import JumbotronTitleBox from "../../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../../components/contact-form/ContactForm";

import BannerImg from "../../images/research/e-commerce/banner-img.svg"

import { Link, animateScroll as scroll } from 'react-scroll';

import ResearchSecondaryNav from "../../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../../components/tracking/Tracking";

class EcommerceResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="EcommerceResearch">
                <ResearchSecondaryNav active="3" language="en"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="e-commerce" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">e-commerce</h5>
                                <JumbotronTitleBox title="Conversion optimization in e-commerce" paragraph="Our research determines the emotions of the recipient and his involvement in the operation at various stages of the purchasing process, to enable you to shorten the user path to finalize the purchase." />
                                <Link to="ContactForm" smooth={true}><button className="btn btn-primary btn-violet mt-s">Order tests</button></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Choose tests</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Research and analysis of the user's path
                                    </h4>
                                    <p className="large">
                                        We explore big data, perform eyetracking tests and supplement the results
                                        with the focus group. The results of these activities will optimize the shopping
                                        path and eliminate elements that disrupt or block the conversion in the online store.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Research on shopping motivation
                                    </h4>
                                    <p className="large">
                                        Eyetracking, skin conductance and EEG create an advanced set of tests that
                                        allows you to take a closer look at the user's emotions and get to know his
                                        real shopping motivation. The obtained results may be factors increasing conversion.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Testing the effectiveness of product cards
                                    </h4>
                                    <p className="large">
                                        We analyze the purchase processes in detail by combining the results of eyetracking
                                        tests with psychophysiological tests and the EEG. Based on the results of the analysis,
                                        you will increase the attractiveness of the rich product cards from the UX perspective.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">04</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Development of an individual research process
                                    </h4>
                                    <p className="large">
                                        Do you need an individually tailored research process to measure your activities
                                        in the area of e-commerce? Our team will design studies precisely tailored
                                        to your needs. Fill out the application form and we will contact you.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{backgroundImage: 'url(' + ContactFormBg + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'left 10% bottom 30%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Leave your details and we will present you with a research plan</h2>
                                <p className="introduction">
                                    Write to us and tell us about your needs, and we will come back with a proposal for research cooperation that will result in the flourishing of your business.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2" formLanguage="en" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default EcommerceResearch;