import React, { Component } from 'react';
import './TitleBox.scss';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';

class TitleBox extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        AOS.init({
            duration : 500
        })
    }

    render() {
        let logo = <div className="logo mb-xs" data-aos="fade-up">{ this.props.logo }</div>;
        let title = this.props.isH1 ? <h1 className="pb-s" data-aos="fade-up">{ this.props.title }</h1> : <h2 className="pb-s" data-aos="fade-up">{ this.props.title }</h2>;
        let paragraph = <p className="introduction" data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html:this.props.paragraph}}></p>;
        let link = <div className="mt-s" data-aos="fade-up" data-aos-delay="400"><Link to={this.props.route} onClick={this.handleClick}>{this.props.link} <i className="material-icons">arrow_forward</i></Link></div>
        let button =<div className="mt-s" data-aos="fade-up" data-aos-delay="400"><Link to={this.props.route} onClick={this.handleClick}><button className="btn btn-primary">{this.props.button} <i className="material-icons">arrow_forward</i></button></Link></div>

        return (
            <div className="TitleBox">
                { this.props.logo ? logo : '' }
                { this.props.title ? title : '' }
                { this.props.paragraph ? paragraph : '' }
                { this.props.link ? link : '' }
                { this.props.button ? button : '' }
            </div>
        );
    }
}

export default TitleBox;
