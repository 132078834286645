import React, {Component} from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../components/contact-form/ContactForm";

import {Link, animateScroll as scroll} from 'react-scroll';


import BannerImg from "../images/research/digital/banner-img.svg"
import ResearchSecondaryNav from "../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class DigitalResearch extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="DigitalResearch">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>AF.lab - badania digital, User Experience, UX, zaangażowanie i atrakcyjność stron
                        internetowych www</title>
                    <meta property="og:title"
                          content="AF.lab - badania digital, User Experience, UX, zaangażowanie i atrakcyjność stron internetowych www"/>
                    <meta name="description"
                          content="User Experience, UX, Analiza ścieżki użytkownika, Testy A/B, Badanie zaangażowania i atrakcyjności layoutów, indywidualny proces badawczy."/>
                    <meta property="og:description"
                          content="User Experience, UX, Analiza ścieżki użytkownika, Testy A/B, Badanie zaangażowania i atrakcyjności layoutów, indywidualny proces badawczy."/>
                </Helmet>
                <ResearchSecondaryNav active="1"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="digital" className="img-fluid d-block mx-auto"/>
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">digital</h5>
                                <JumbotronTitleBox
                                    title="Badania użyteczności stron www, interfejsów użytkowników i&nbsp;rozwiązań digitalowych"
                                    paragraph="Przeanalizowane i przygotowane w oparciu o raport badawczy strony internetowe oraz inne nośniki digitalowe spełnią swoje zadanie lepiej, przykuwając uwagę odbiorcy i zwiększając jego zaangażowanie."/>
                                <Link to="ContactForm" smooth={true}>
                                    <button className="btn btn-primary btn-violet mt-s">Zamów badania</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Wybierz badania dla Twoich produktów digitalowych</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badania użyteczności, analiza i audyt UX i UI
                                    </h4>
                                    <p className="large">
                                        W ramach badań użyteczności zidentyfikujemy wszystkie najważniejsze elementy
                                        związane z Twoją stroną internetową, interfejsem dla użytkownika i wszystkimi
                                        innymi rozwiązaniami digitalowymi.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Analiza ścieżki użytkownika
                                    </h4>
                                    <p className="large">
                                        Działanie to ma na&nbsp;celu optymalizację prezentacji informacji
                                        na&nbsp;stronie
                                        internetowej tak, by&nbsp;poruszanie się w&nbsp;serwisie internetowym było
                                        bardziej
                                        intuicyjne i&nbsp;prostsze dla&nbsp;użytkownika. Zakres standardowy oparty jest
                                        o&nbsp;analizę big data, a&nbsp;zakres rozszerzony obejmuje dodatkowo
                                        eyetracking i&nbsp;pracę z&nbsp;grupą focusową.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Testy A/B
                                    </h4>
                                    <p className="large">
                                        Posługując się nowoczesnymi narzędziami naukowymi jesteśmy w&nbsp;stanie
                                        w&nbsp;warunkach
                                        laboratoryjnych przeprowadzić testy A/B formatów reklamowych. Dodanie
                                        do&nbsp;tego
                                        badań EEG tworzy zaawansowany zestaw testów, który pozwala przyjrzeć się temu,
                                        czy&nbsp;dany sposób komunikacji jest dla&nbsp;odbiorcy interesujący.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">04</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie zaangażowania i&nbsp;atrakcyjności layoutów
                                    </h4>
                                    <p className="large">
                                        Analiza layoutu strony internetowej w&nbsp;oparciu o&nbsp;eyetracking
                                        i&nbsp;testy
                                        psychofizjologiczne pozwala zbadać poziom występowania emocji oraz
                                        zainteresowania,
                                        jakie towarzyszą odbiorowi strony internetowej.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">05</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Opracowanie indywidualnego procesu badawczego
                                    </h4>
                                    <p className="large">
                                        Potrzebujesz indywidualnie dopasowanego procesu badawczego, by&nbsp;zbadać swoje
                                        działania w&nbsp;obszarze Digital? Nasz zespół zaprojektuje badania precyzyjnie
                                        dostosowane do&nbsp;Twoich potrzeb. Wypełnij formularz zgłoszeniowy, a&nbsp;my
                                        skontaktujemy się z&nbsp;Tobą.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{
                    backgroundImage: 'url(' + ContactFormBg + ')',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'left 10% bottom 30%'
                }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Zostaw swoje dane, a&nbsp;przedstawimy Ci plan badań</h2>
                                <p className="introduction">
                                    Napisz do&nbsp;nas i&nbsp;opowiedz o&nbsp;swoich potrzebach, a&nbsp;wrócimy
                                    z&nbsp;propozycją współpracy badawczej, która zaowocuje rozkwitem Twojego biznesu.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2"/>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default DigitalResearch;
