import React, { Component } from 'react';
import './CounterItem.scss';

class CounterItem extends Component {

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="CounterItem text-center">
                {this.props.title ? (<h2 className="bigger white">{this.props.title}</h2>) : ''}
                {this.props.paragraph ? (<p className="introduction">{this.props.paragraph}</p>) : ''}
            </div>
        );
    }
}

export default CounterItem;
