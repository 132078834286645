import React, { Component } from 'react';
import './Modal.scss'; // Tell Webpack that Button.js uses these styles

class Modal extends Component {
    constructor(props) {
        super(props);
        this.state = {

        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.props.fClose();
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <section className={"Modal " + (this.props.visible ? "open" : "closed")} id={this.props.id}>
                <div className="modal-backdrop"></div>
                <div className="modal-wrapper">
                    <div className="modal-close-wrapper">
                        <div className="modal-close" onClick={this.handleClick}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className="modal-content">
                        {this.props.children}
                    </div>
                </div>
            </section>
        );
    }
}

export default Modal;