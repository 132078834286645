import React, { Component } from 'react';
import './ContactBox.scss';
import AOS from 'aos';
import 'aos/dist/aos.css';

class ContactBox extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        AOS.init({
            duration : 500
        })
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className="ContactBox text-center mb-xs" data-aos="zoom-in">
                <figure className="mb-s">
                    <div className="placeholder" style={{backgroundImage: 'url(' + this.props.image + ')'}}></div>
                </figure>
                <h4 className="mb-xs">{this.props.title}</h4>
                <h5>{this.props.name}</h5>
                <p className="large"><a href={"tel:" + this.props.phone}>{this.props.phone}</a></p>
                <p className="large"><a href={"mailto:" + this.props.email}>{this.props.email}</a></p>
            </div>
        );
    }
}

export default ContactBox;