import React, { Component } from 'react';
import './Accordion.scss';
import AccordionItem from "./accordion-item/AccordionItem";

class Accordion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current: 0,
        };
        this.handleOpen = this.handleOpen.bind(this);
    }

    handleOpen(idx) {
        this.setState(state => ({
            current: idx
        }))
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <div className={"Accordion " + this.props.className}>
                {this.props.children.map((item, index) => (
                    <AccordionItem key={index} index={index} label={item.props.label} currentItem={this.state.current} handleOpen={this.handleOpen} isOpened={this.state.current === index}>
                            {item.props.children}
                    </AccordionItem>
                ))}
            </div>
        );
    }
}

export default Accordion;