import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import ContactForm from "../components/contact-form/ContactForm";
import { Link, animateScroll as scroll } from 'react-scroll';

import BannerImg from "../images/research/e-learning/banner-img.svg";

import ResearchSecondaryNav from "../components/research-secondary-nav/ResearchSecondaryNav";

import ContactFormBg from '../images/contact-form/bg-2.svg';
import {initGA, PageView} from "../components/tracking/Tracking";

import {Helmet} from "react-helmet";

class ElearningResearch extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        initGA('UA-142608572-1');
        PageView();
    }
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="ElearningResearch">
                <Helmet>
                    {/*<meta charSet="utf-8" />*/}
                    <title>AF.lab - badania efektywności i User Experience elearning</title>
                    <meta property="og:title" content="AF.lab - badania efektywności i User Experience elearning" />
                    <meta name="description" content="Badanie i analiza efektywności materiałów szkoleniowych e-learningowych, badania UX materiałów elearning, indywidualny proces badawczy."/>
                    <meta property="og:description" content="Badanie i analiza efektywności materiałów szkoleniowych e-learningowych, badania UX materiałów elearning, indywidualny proces badawczy." />
                </Helmet>
                <ResearchSecondaryNav active="2"/>
                <section className="pt-xl pb-m">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-6 order-lg-2">
                                <img src={BannerImg} alt="e-learning" className="img-fluid d-block mx-auto" />
                            </div>
                            <div className="col-12 col-lg-6 offset-xl-1 col-xl-5">
                                <h5 className="coral">e-learning</h5>
                                <JumbotronTitleBox title="Wzrost jakości materiałów szkoleniowych" paragraph="Materiały e-learningowe przygotowane w oparciu o stworzony przez nas raport badawczy stają się łatwiej przyswajalne, ciekawsze i w efekcie skuteczniejsze." />
                                <Link to="ContactForm" smooth={true}><button className="btn btn-primary btn-violet mt-s">Zamów badania</button></Link>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="container pb-m">
                    <div className="row">
                        <div className="col-12">
                            <div className="line"></div>
                        </div>
                    </div>
                </div>

                <article className="mb-xl research-offer">
                    <section>
                        <div className="container">
                            <div className="row mb-xs">
                                <div className="col-8 offset-4 col-sm-9 offset-sm-3 col-lg-9 offset-lg-3 col-xl-6">
                                    <h2>Wybierz badania</h2>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">01</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Analiza zaangażowania odbiorcy
                                    </h4>
                                    <p className="large">
                                        W&nbsp;podstawowym pakiecie przeprowadzamy badanie eyetracking skoncentrowane
                                        na&nbsp;UX materiału szkoleniowego. Wzbogacamy je o&nbsp;wywiad pogłębiony,
                                        ujawniający subiektywne odczucia użytkowników. Zakres rozszerzony to&nbsp;analiza
                                        eyetrackingowa layoutu połączona z&nbsp;biofeedbackiem i&nbsp;pracą z&nbsp;grupą focusową.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">02</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Badanie efektywności materiałów szkoleniowych
                                    </h4>
                                    <p className="large">
                                        Oprócz wykorzystania narzędzi z&nbsp;zakresu rozszerzonego, analiza obejmuje również
                                        badanie EEG/QEEG mózgu. Wskazujemy obszar i&nbsp;stopień stymulacji kory mózgowej,
                                        by&nbsp;określić skuteczność przygotowanego materiału. Wyniki uzupełnione zostają wywiadami pogłębionymi.
                                    </p>
                                </div>
                            </div>
                            <div className="row pb-sm">
                                <div className="col-4 col-sm-3 offset-lg-1 col-lg-2">
                                    <p className="research-offer-number">03</p>
                                </div>
                                <div className="col-8 col-sm-9 col-lg-9 col-xl-6">
                                    <h4 className="mb-s mt-xs">
                                        Opracowanie indywidualnego procesu badawczego
                                    </h4>
                                    <p className="large">
                                        Potrzebujesz indywidualnie dopasowanego procesu badawczego, by&nbsp;zbadać swoje działania
                                        w&nbsp;obszarze e-learning? Nasz zespół zaprojektuje badania precyzyjnie dostosowane
                                        do&nbsp;Twoich potrzeb. Wypełnij formularz zgłoszeniowy, a&nbsp;my skontaktujemy się z&nbsp;Tobą
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </article>

                <section className="ContactForm inverse pt-s pb-xl" style={{backgroundImage: 'url(' + ContactFormBg + ')', backgroundRepeat: 'no-repeat', backgroundPosition: 'left 10% bottom 30%'}}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12 col-lg-6 col-xl-5 offset-xl-1 mb-sm">
                                <h2 className="mb-sm">Zostaw swoje dane, a&nbsp;przedstawimy Ci plan badań</h2>
                                <p className="introduction">
                                    Napisz do&nbsp;nas i&nbsp;opowiedz o&nbsp;swoich potrzebach, a&nbsp;wrócimy z&nbsp;propozycją współpracy badawczej, która zaowocuje rozkwitem Twojego biznesu.
                                </p>
                            </div>
                            <div className="col-12 col-lg-6 col-xl-5">
                                <ContactForm formType="2" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        );
    }
}

export default ElearningResearch;