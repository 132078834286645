import React, { Component } from 'react';
import './LetsWork.scss'; // Tell Webpack that Button.js uses these styles
import TitleBox from '../title-box/TitleBox';
import { BrowserRouter as Router, Link } from 'react-router-dom';

class LetsWork extends Component {

    handleClick() {
        // this.props.fToggle();
        window.scrollTo(0, 0);
    }

    render() {
        // You can use them as regular CSS styles
        return (
            <section className="LetsWork py-m">
                <div className="jumbotron-fluid">
                    <div className="container">
                        {this.props.language === 'en' ? (
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-lg-7 col-xl-6">
                                    <TitleBox title="Order tests" paragraph="We know how to make your business flourish.<br />Contact us to get a personalized offer." />
                                    {/*<Link to="/order-research/"><button className="btn btn-outline-light mt-sm">Napisz do nas</button></Link>*/}
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <Link to="/en/order-research/"><button className="btn btn-outline-light mt-sm w-100">Contact us</button></Link>
                                </div>
                            </div>
                        ) : (
                            <div className="row justify-content-center align-items-center">
                                <div className="col-12 col-lg-7 col-xl-6">
                                    <TitleBox title="Zamów badanie" paragraph="Wiemy, jak sprawić, aby Twój biznes rozkwitł.<br />Skontaktuj się z nami, aby uzyskać spersonalizowaną ofertę." />
                                    {/*<Link to="/order-research/"><button className="btn btn-outline-light mt-sm">Napisz do nas</button></Link>*/}
                                </div>
                                <div className="col-12 col-lg-3 col-xl-2">
                                    <Link to="/zamow-badania/" onClick={this.handleClick}><button className="btn btn-outline-light mt-sm w-100">Napisz do nas</button></Link>
                                    {/*<Link to="/zamow-badania/" onClick={this.handleClick}><button className="btn btn-dark">Zamów badania</button></Link>*/}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        );
    }
}

export default LetsWork;