import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from 'react-router-dom';
import './Card.scss';
import DotBg from '../../images/dot-bg.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';

class Card extends Component {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick() {
        window.scrollTo(0, 0);
    }

    componentDidMount() {
        AOS.init({
            duration : 500
        })
    }

    render() {
        // You can use them as regular CSS styles
        const image = <div className="card-image"><img src={this.props.image} alt={this.props.title} className="img-fluid" /></div>;
        return (
            <div className="Card" data-aos="fade-up">
                <div className="dot-bg" style={{backgroundImage: 'url(' + DotBg + ')'}}></div>
                {this.props.image ? image : ''}
                <h3 className="smaller">{this.props.title}</h3>
                <p className="introduction">{this.props.paragraph}</p>
                <div className="btn-container mt-l"><Link to={this.props.route} onClick={this.handleClick}><button className="btn btn-primary">{this.props.button} <i className="material-icons">arrow_forward</i></button></Link></div>
            </div>
        );
    }
}

export default Card;
