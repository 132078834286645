import React, { Component } from 'react';
import JumbotronTitleBox from "../components/jumbotron-title-box/JumbotronTitleBox";
import JobBox from "../components/job-box/JobBox";
import TitleBox from "../components/title-box/TitleBox";
import LogoBox from "../components/logo-box/LogoBox";
import JoinUs from "../components/join-us/JoinUs";

class CareersItem extends Component {
    render() {
        // You can use them as regular CSS styles
        return (
            <div className="CareersItem">
                <section className="CareersItem-header">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-7 col-xl-6 py-l">
                                <h6 className="uc">Apply as</h6>
                                <h2>Copywriter</h2>
                                <p className="introduction">Full Time</p>
                            </div>
                            <div className="col-12 col-lg-5 col-xl-4 pt-l">
                                <JobBox title="Copywriter" hours="Full Time" button="Apply"/>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="py-xl">
                    <div className="container">
                        <div className="row">
                            <div className="col-12 offset-md-1 col-md-10 col-lg-8 col-xl-6">
                                <div className="mb-sm">
                                    <h4>Your duties</h4>
                                    <ul>
                                        <li>preparation of text materials depending on their purpose;</li>
                                        <li>creating content based on briefs to appropriate target groups;</li>
                                        <li>support of the creative department in visualizing the idea;</li>
                                        <li>active acquisition of knowledge about supported projects;</li>
                                        <li>presenting recommendations to clients;</li>
                                        <li>preparation of presentations summarizing the performed activities;</li>
                                    </ul>
                                </div>
                                <div className="mb-sm">
                                    <h4>Requirements</h4>
                                    <ul>
                                        <li>excellent knowledge of Polish (native language), rules of spelling, spelling and punctuation;</li>
                                        <li>experience in creating content for various marketing tools, including, above all; slogans, advertising slogans and name’ing;</li>
                                        <li>experience in creating narrative, storytelling and scripts;</li>
                                        <li>good knowledge of the MS Office package (Word, Excel, PowerPoint),</li>
                                        <li>very good knowledge of English;</li>
                                        <li>interests supported by knowledge in the field of new technologies and digital marketing;</li>
                                    </ul>
                                </div>
                                <div>
                                    <h4>What we offer</h4>
                                    <ul>
                                        <li>cooperation with ambitious, interesting and developing projects for leading global and Polish brands;</li>
                                        <li>Multisport card and health insurance;</li>
                                        <li>business computer and telephone as well as comfortable working conditions;</li>
                                        <li>work in a young team in a historic tenement house in the center of Poznań;</li>
                                        <li>remuneration adequate to the competences held on a contract of employment or B2B;</li>
                                        <li>trips and integration meetings;</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <JoinUs />
            </div>
        );
    }
}

export default CareersItem;